<div class="offline" *ngIf="!isOnline">
  <b>{{ 'auth.offline.label' | translate }}</b>
  <p>{{ 'auth.offline.description' | translate }}</p>
</div>

<div class="language">
  <select class="form-control" name="selectLang" (change)="handlerChangeLanguage($event)">
    <option *ngFor="let lang of availableLanguages" [value]="lang" [selected]="lang === currentLanguage">
      {{ 'languages.' + lang + '.label' | translate }}
    </option>
  </select>
</div>

<div class="screen">
  <div class="content">
    <div class="description" [innerHtml]="'auth.message' | translate"></div>
    <div class="code" [ngClass]="{ 'loading': isDefaultWidgetCode }">
      {{ widgetCode }}
    </div>
    <div class="logo">Oongalee</div>
  </div>
</div>
