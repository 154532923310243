import { Component, OnInit } from '@angular/core';
import { NewMenuStorageService } from '../../../../../app/services/new-menu-storage.service';
import {IMenuCategory, IMenuGCategory, IProducts} from '../../../../../app/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {distinctUntilChanged, filter, map, mergeMap, switchMap, take, toArray} from 'rxjs/operators';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { NewMenuSyncService } from '../../../../../app/services/new-menu-sync.service';
import { fromArray } from '../../../../../app/models/special.model';
import { ConnectionStatusService, FileCacheService } from '../../../../../app/services';
import {NewMenuApiService} from '../../../../../app/services/new-menu-api.service';

@UntilDestroy()
@Component({
  selector: 'app-new-menu-tab',
  templateUrl: './new-menu-tab.component.html',
  styleUrls: ['./new-menu-tab.component.scss']
})

export class NewMenuTabComponent implements OnInit {
  public categories: IMenuCategory[] | [] = [];
  public currentCategory!: IMenuCategory;
  public isCategoryOpen = false;
  public isProductOpen = false;
  public currentProduct!: IProducts;
  public gCatId: number | null = null;
  public gCategories!: IMenuGCategory[];
  public isLoading = true;

  constructor(
   private menuStorage: NewMenuStorageService,
   private networkConnection: ConnectionStatusService,
   private filesCache: FileCacheService,
   private domSanitizer: DomSanitizer,
   private menuApi: NewMenuApiService,
   private syncService: NewMenuSyncService,
  ) {
    this.gCategories = this.menuApi.menuGCategory.getValue();
    this.menuApi.menuGCategory.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
      filter((menuGCategory: IMenuGCategory[]) => menuGCategory && menuGCategory.length > 0),
    ).subscribe((gCategory) => {
      this.gCatId = gCategory.length > 0 ? gCategory[0].hierarchyId : null;
    });
  }

  ngOnInit(): void {
    this.isLoading = this.menuStorage.isLoading.getValue();
    this.menuStorage.isLoading.pipe(
      untilDestroyed(this),
      filter((loading: boolean) => !loading),
      take(1)
    ).subscribe(() => {
      if (this.gCatId) {
        this.setActive(this.gCatId);
      }
    });
  }

  get isOnline(): boolean {
    return this.networkConnection.status$.getValue();
  }
  showCategory(category: IMenuCategory): void {
    this.currentCategory = category;
    this.isCategoryOpen = true;
  }

  showProduct(product: IProducts): void {
    this.currentProduct = product;
    this.isProductOpen = true;
  }

  isActiveSection(hierarchyId: number): boolean {
    return this.gCatId === hierarchyId;
  }

  setActive(hierarchyId: number): void {
    this.isCategoryOpen = false;
    this.categories = [];
    this.gCatId = hierarchyId;
    this.menuStorage.getCategoriesByHierarchyId(hierarchyId).pipe(
      switchMap((categories: IMenuCategory[]) => {
          return fromArray(categories).pipe(
            mergeMap((category) => {
              if (typeof category.bgSku === 'string' && category.hierarchyId !== 735) {
                return this.filesCache.getFile(category.bgSku).pipe(
                  map(file => this.domSanitizer.bypassSecurityTrustUrl(file?.objectUrl)),
                  map(file => ({ ...category, sanitizedUrl: file }))
                );
              }
              return of(category);
            }),
            toArray(),
          );
      }),
      untilDestroyed(this)
    ).subscribe(category => {
      if (category.length === 1) {
        this.showCategory(category[0]);
        this.isCategoryOpen = true;
        this.isLoading = false;
      } else {
        this.categories = category;
        this.isLoading = false;
      }
    });
  }

  getDefaultBg(category: IMenuCategory): string {
    const foundCategory = this.gCategories.find(cat => cat.hierarchyId === category.hierarchyId);
    return foundCategory ? foundCategory.defaultCategoryBgImage ?? '' : '';
  }
}
