<div class="section spinach" (click)="setState('spinach')" appUserActivityClickTracking="SpinechOpen">
  <img class="section__image" src="themes/kelseys/assets/images/freeSpinachDip.jpg" alt="spinech">
  <span class="section__name">Free Spinach Dip</span>
</div>

<div class="section cards" (click)="setState('cards')" appUserActivityClickTracking="CardsOpen">
  <img class="section__image" src="themes/kelseys/assets/images/giftCards.jpg" alt="cards">
  <span class="section__name">Gift Cards</span>
</div>

<div class="section events" (click)="setState('events')" appUserActivityClickTracking="EventsOpen">
  <img class="section__image" src="themes/kelseys/assets/images/groupEvents.jpg" alt="events">
  <span class="section__name">Group Events & Parties</span>
</div>

<div class="section kids" (click)="setState('kids')" appUserActivityClickTracking="KidsOpen">
  <img class="section__image" src="themes/kelseys/assets/images/kids.jpg" alt="kids">
  <span class="section__name">Kids Zone</span>
</div>

<div class="section social" (click)="setState('app')" appUserActivityClickTracking="AppOpen">
  <img class="section__image" src="themes/kelseys/assets/images/kelseyApp.jpg" alt="app">
  <span class="section__name">Kelseys APP</span>
</div>

<div class="section social" (click)="setState('social')" appUserActivityClickTracking="SocialOpen">
  <img class="section__image" src="themes/kelseys/assets/images/socials.jpg" alt="social">
  <span class="section__name">Social</span>
</div>

<app-kelseys-more-section
  *ngIf = "!isSections"
  @InOutAnimation
  (backToSections) = closeSection($event)
  [data] = "sectionData"
  [appActivitySection] = this.state
></app-kelseys-more-section>
