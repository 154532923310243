import { Injectable } from '@angular/core';
import { concat, Observable, of, throwError } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { FileCacheModel } from '../models';
import { FileDownloaderService } from './file-downloader.service';
import { FileCacheStorageService } from './file-cache.storage.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FileCacheService {

  protected constructor(
    protected readonly downloader: FileDownloaderService,
    protected readonly storage: FileCacheStorageService,
  ) {}

  public getFiles(urls: string[]): Observable<FileCacheModel[]> {
    return concat(
      ...urls.map((url) => this.getFile(url)),
    ).pipe(
      toArray(),
      map((files) => {
        return files.sort((a, b) => urls.indexOf(a.url) - urls.indexOf(b.url));
      }),
    );
  }

  public getFile(url: string): Observable<FileCacheModel> {
    const fileDownloader$ = this.downloader.downloadAsBlob(url).pipe(
      switchMap((blob) => this.storage.add(url, blob)),
      switchMap((file) => {
        if (file instanceof FileCacheModel) {
          return of(file);
        }

        return throwError('This is an error!');
      })
    );

    return this.storage.get(url).pipe(
      switchMap(file => {
        if (file instanceof FileCacheModel) {
          return of(file);
        }

        return fileDownloader$;
      }),
    );
  }

  public delete(url: string): Observable<unknown> {
    return this.storage.delete(url);
  }

  public bulkDelete(urls: string[]): Observable<unknown> {
    return this.storage.bulkDelete(urls);
  }

  public clearCache(): Observable<boolean> {
    return this.storage.clear();
  }

}
