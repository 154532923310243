<div class="loader" *ngIf="loading"></div>
<video #videoPlayer1
       preload="auto"
       playsinline
       autoplay
       width="100%"
       [src]=""
       (ended)="playNextVideo()">
</video>

<video #videoPlayer2
       style="display: none"
       preload="auto"
       playsinline
       autoplay
       width="100%"
       [src]=""
       (ended)="playNextVideo()">
</video>

