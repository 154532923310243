import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-bp-rewards',
  templateUrl: './bp-rewards.component.html',
  styleUrls: ['./bp-rewards.component.scss']
})
export class BpRewardsComponent {
  @Output() backToSections = new EventEmitter<boolean>();

  public back(): void {
    this.backToSections.emit(true);
  }
}
