import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { map, observeOn, takeUntil } from 'rxjs/operators';
import { animationFrameScheduler, BehaviorSubject, fromEvent, interval, merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SafeUrl } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../../environments/environment';
import { ISpecial } from '../../../models/special.model';
import { SpecialsStorageService } from '../../../services/specials.storage.service';

enum AnimationState {
  Active = 'active',
  Previous = 'previous',
  Next = 'next'
}

@UntilDestroy()
@Component({
  selector: 'app-special',
  templateUrl:
    environment.featureToggle.theme === 'default'
      ? './special.component.html'
      : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/special-tab/special.component.html`,
  styleUrls: [
    environment.featureToggle.theme === 'default'
      ? './special.component.scss'
      : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/special-tab/special.component.scss`,
  ],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateY(0)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateY(0)' }))
      ])
    ]),
    trigger('InOutAnimation', [
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class SpecialTabComponent implements OnInit, AfterViewInit{
  @Output() backToSections = new EventEmitter<boolean>();

  public specials: ISpecial[] = [];
  public slides: SafeUrl[] = [];
  public index = 0;
  public isAnimating = false;
  public indicators: number[] = [];
  public readonly loading$ = new BehaviorSubject<boolean>(true);

  @ViewChild('content', { static: false })
  private content!: ElementRef;

  constructor(
    private readonly specialsStorage: SpecialsStorageService,
  ) {
    this.loading$.next(true);
  }

  ngOnInit(): void {
    this.specialsStorage.gettAllSpecials().pipe(
        observeOn(animationFrameScheduler),
        untilDestroyed(this),
        map((specials: ISpecial[]) => specials.sort((a, b) => a.sequence - b.sequence)),
    ).subscribe((specials: ISpecial[]) => {
      this.specials = specials;
      this.addSlides(specials);
      this.loading$.next(false);
    });
  }

  ngAfterViewInit(): void {
    fromEvent<TouchEvent>(this.content.nativeElement, 'touchstart', {passive: true}).subscribe(startEvent => {
      const startY = startEvent.changedTouches[0].screenY;

      const touchEndSubscription = fromEvent<TouchEvent>(this.content.nativeElement, 'touchend', {passive: true}).subscribe(endEvent => {
        const endY = endEvent.changedTouches[0].screenY;

        const difference = startY - endY;
        const direction = difference <= 20 && difference >= -20
            ? 'click'
            : difference > 20
                ? 'up'
                : 'down';

        this.checkDirection(direction);

        touchEndSubscription.unsubscribe();
      });
    });

    interval(5000).pipe(
        takeUntil(merge(
            fromEvent(this.content.nativeElement, 'touchmove', {passive: true}),
            fromEvent(this.content.nativeElement, 'mousemove', {passive: true}),
            fromEvent(this.content.nativeElement, 'click', {passive: true}),
        )),
        untilDestroyed(this),
    ).subscribe(() => {
      if (!this.isAnimating) {
        this.getNext();
      }
    });
  }

  public back(): void {
    this.backToSections.emit(true);
  }

  private addSlides(specials: ISpecial[]): void {
    const slides: SafeUrl[] = [];
    const today = new Date();

    specials.forEach((special: ISpecial) => {
      const start = new Date(special.scheduleStart);
      const end = new Date(special.scheduleEnd);
      if (today >= start && today <= end) {
       slides.push(special.contentLocal);
       this.indicators.push(special.id);
      }
    });

    if (slides.length <= 3 && slides.length > 1) {
      this.slides = slides.concat(slides);
    } else if (slides.length === 1) {
      this.slides = slides;
    } else {
      this.slides = slides;
    }

  }

  private checkDirection(direction: string): void {
    if (direction !== 'click') {
      direction === 'up'
        ? this.getNext()
        : this.getPrev();
    }
  }

  getPrev(): void {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.index = (this.index - 1 + this.slides.length) % this.slides.length;
      setTimeout(() => this.isAnimating = false, 300);
    }
  }

  getNext(): void {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.index = (this.index + 1) % this.slides.length;
      setTimeout(() => this.isAnimating = false, 300);
    }
  }

  shouldShowPrevious(index: number): boolean {
    const prevIndex = (this.index - 1 + this.slides.length) % this.slides.length;
    return index === prevIndex;
  }

  shouldShowNext(index: number): boolean {
    const nextIndex = (this.index + 1) % this.slides.length;
    return index === nextIndex;
  }

  getAnimationState(index: number): AnimationState | null {

    if (index === this.index) {
      return AnimationState.Active;
    } else if (index === (this.index - 1 + this.slides.length) % this.slides.length) {
      return AnimationState.Previous;
    } else if (index === (this.index + 1) % this.slides.length) {
      return AnimationState.Next;
    } else {
      return null;
    }
  }

  get isLoading(): boolean {
    return this.loading$.getValue();
  }
}
