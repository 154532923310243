import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeResourceUrl'
})
export class SafeResourceUrlPipe implements PipeTransform {

  constructor(
    private readonly sanitizer: DomSanitizer
  ) { }

  transform(url: string | null): SafeUrl | null {
    if (url) {
      this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    return null;
  }

}
