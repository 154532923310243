import { SafeUrl } from '@angular/platform-browser';
import {EMPTY, from} from 'rxjs';
export interface ISpecialRaw {
  id: number;
  ownerType: string;
  name: string;
  scheduleStart: string;
  scheduleEnd: string;
  sequence: number;
  content: string;
  updatedAt: string;
  createdAt: string;
}

export interface ISpecial extends ISpecialRaw {
  contentLocal: SafeUrl;
}

export const arrayDiff = <T>(
  arr1: T[],
  arr2: T[],
  compare: (a: T, b: T) => boolean = (a, b) => a === b,
) => arr1.filter(element => !arr2.some((value) => {
  return compare(value, element);
}));
export const arrayDiffIdCompare = <T extends { id: number }>(a: T, b: T) => a.id === b.id;

export const fromArray = <T>(items: T[]) => items.length === 0 ? EMPTY : from(items);
