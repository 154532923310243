import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IKeyValue } from '../models';


@Injectable({
  providedIn: 'root'
})
export class KeyValueStorageService {

  protected readonly collectionName = 'keyValue';

  protected constructor(
    protected readonly db: NgxIndexedDBService,
  ) {}

  public set(data: IKeyValue): Observable<IKeyValue | undefined> {
    return this.db.update(this.collectionName, data).pipe(
      switchMap(() => this.get(data.key))
    );
  }

  public get(key: string): Observable<IKeyValue | undefined> {
    return this.db.getByKey(this.collectionName, key);
  }

  public delete(key: string): Observable<unknown> {
    return this.db.delete(this.collectionName, key);
  }

  public bulkDelete(key: string[]): Observable<unknown> {
    return this.db.bulkDelete(this.collectionName, key);
  }

  public clear(): Observable<boolean> {
    return this.db.clear(this.collectionName);
  }

}
