import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cbh-rewards-tab',
  templateUrl: './cbh-rewards-tab.component.html',
  styleUrls: ['./cbh-rewards-tab.component.scss']
})
export class CbhRewardsTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
