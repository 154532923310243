import { environment } from '../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {ConnectionStatusService, RestaurantTableService, WebsocketService, WidgetUiConfigService} from '../../../services';

@UntilDestroy()
@Component({
  selector: 'app-service-centre',
  templateUrl: environment.featureToggle.theme === 'default'
    ? './service-centre.component.html'
    : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/service-centre/service-centre.component.html`,
  styleUrls: [environment.featureToggle.theme === 'default'
    ? './service-centre.component.scss'
    : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/service-centre/service-centre.component.scss`]
})
export class ServiceCentreComponent implements OnInit {

  tableName: string | null = null;
  wifiName: string | null = null;
  wifiPassword: string | null = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly webSocketService: WebsocketService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
    private readonly connectionStatusService: ConnectionStatusService,
  ) {}

  ngOnInit(): void {
    this.restaurantTableService.table$.pipe(
      untilDestroyed(this),
    ).subscribe((tableInfo) => {
      this.tableName = tableInfo?.tableName ?? null;
      this.wifiName = tableInfo?.wifiName ?? null;
      this.wifiPassword = tableInfo?.wifiPassword ?? null;
    });
  }

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get withCallWaiter(): boolean {
    return environment.featureToggle.withCallWaiter && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterFeatures(): boolean {
   const withCallWaiter = this.restaurantTableService.table$.getValue()?.features?.withCallWaiter;

   if (withCallWaiter !== undefined) {
     return withCallWaiter;
   }
   return true;
  }

  get withCallWaiterToRepeat(): boolean {
    return environment.featureToggle.withCallWaiterToRepeat && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterToPay(): boolean {
    return environment.featureToggle.withCallWaiterToPay && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get isOnline(): boolean {
    return this.connectionStatusService.isOnline;
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public changeLanguage(language: string): void {
    this.translate.use(language);
  }

  handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiter$.next(!this.callWaiter);
    }
  }

  handlerCallWaiterToPay($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToPay$.next(!this.callWaiterToPay);
    }
  }

  handlerCallWaiterToRepeat($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToRepeat$.next(!this.callWaiterToRepeat);
    }
  }

}
