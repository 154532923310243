import {
  Directive,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appObserve]',
  exportAs: 'intersection',
})
export class ObserveElementDirective implements OnInit {

  @Input()
  public observeRoot: HTMLElement | null = null;

  @Input()
  public observeRootMargin = '0px 0px 0px 0px';

  @Input()
  public observeThreshold = 0;

  @Input()
  public observeDebounceTime = 500;

  @Input()
  public observeContinuous = false;

  @Output()
  public observeIntersecting = new EventEmitter<boolean>();

  private intersectionObserverOptions: IntersectionObserverInit = {
    root: this.observeRoot,
    rootMargin: this.observeRootMargin,
    threshold: this.observeThreshold,
  };

  constructor(
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    new Observable<boolean>(subscriber => {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.length === 0) {
          return;
        }

        const { isIntersecting } = entries[0];
        subscriber.next(isIntersecting);

        if (isIntersecting && !this.observeContinuous) {
          intersectionObserver.disconnect();
        }
      }, this.intersectionObserverOptions);

      intersectionObserver.observe(this.element.nativeElement);

      return {
        unsubscribe(): void {
          intersectionObserver.disconnect();
        },
      };
    }).pipe(
      debounceTime(this.observeDebounceTime),
      untilDestroyed(this)
    ).subscribe((status) => {
      this.observeIntersecting.emit(status);
    });
  }
}
