import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IRestaurantTable, IKeyValue } from '../models';
import { AuthService } from './auth.service';
import { KeyValueStorageService } from './key-value.storage.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RestaurantTableService {

  protected readonly storageKey = 'restaurantTable';

  public readonly callWaiter$ = new BehaviorSubject<boolean>(false);
  public readonly callWaiterToPay$ = new BehaviorSubject<boolean>(false);
  public readonly callWaiterToRepeat$ = new BehaviorSubject<boolean>(false);

  public readonly table$ = new BehaviorSubject<IRestaurantTable | null>(null);
  public readonly batteryLevel$ = new BehaviorSubject<{ level: number; charging: boolean } | null>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly keyValueStorage: KeyValueStorageService
  ) {
    this.getData().pipe(
      untilDestroyed(this),
    ).subscribe((entry) => {
      this.table$.next(entry);
    });

    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.stopAllCallWaiter();
      this.clearData().pipe(
        untilDestroyed(this),
      ).subscribe();
    });
  }

  public stopAllCallWaiter(): void {
    this.callWaiter$.next(false);
    this.callWaiterToPay$.next(false);
    this.callWaiterToRepeat$.next(false);
  }

  public getData(): Observable<IRestaurantTable> {
    return this.keyValueStorage.get(this.storageKey).pipe(
      filter((entry): entry is IKeyValue => {
        return typeof entry !== 'undefined';
      }),
      pluck('value')
    );
  }

  public updateData(table: IRestaurantTable): Observable<IRestaurantTable> {
    return this.clearData(false).pipe(
      switchMap(() => {
        return this.keyValueStorage.set({
          key: this.storageKey,
          value: table,
        });
      }),
      switchMap(() => this.getData()),
      tap((entry) => this.table$.next(entry))
    );
  }

  public clearData(clearTable = true): Observable<unknown> {
    return this.keyValueStorage.get(this.storageKey).pipe(
      switchMap(() => {
        return this.keyValueStorage.delete(this.storageKey);
      }),
      tap(() => clearTable && this.table$.next(null))
    );
  }

}
