import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EMPTY, BehaviorSubject, from } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RGBA } from 'color-blend/dist/types';
import { WidgetUIConfiguration } from '../models';
import { FileCacheService } from './file-cache.service';
import { RestaurantTableService } from './restaurant-table.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class WidgetUiConfigService {

  private readonly files$ = new BehaviorSubject<Record<string, SafeUrl>>({});

  constructor(
    private readonly restaurantTableService: RestaurantTableService,
    private readonly filesCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer
  ) {
    const urlCached$ = (urls: string[]) => from(urls).pipe(
      mergeMap((url) => this.filesCache.getFile(url)),
      map((file) => ({
        [file.url]: this.domSanitizer.bypassSecurityTrustUrl(
          file.objectUrl
        )
      })),
      toArray(),
    );

    this.restaurantTableService.table$.pipe(
      switchMap((table) => {
        const files = [
          table?.widgetUIConfig.logo,
          table?.widgetUIConfig.pages?.menu?.icon,
          table?.widgetUIConfig.pages?.serviceCenter?.icon,
          table?.widgetUIConfig.pages?.invoice?.icon,
        ].filter((url): url is string => {
          return typeof url === 'string' && !url.startsWith('data:');
        });

        return files.length > 0 ? urlCached$(files) : EMPTY;
      }),
      untilDestroyed(this),
    ).subscribe((results) => {
      this.files$.next(results.reduce((acc, file) => {
        return {...acc, ...file};
      }, {}));
    });
  }

  get widgetUIConfig(): WidgetUIConfiguration | null {
    return this.restaurantTableService.table$.getValue()?.widgetUIConfig ?? null;
  }

  get toolbarButtonsCount(): number {
    let count = 0;

    if (this.menuPageEnabled) {
      count++;
    }

    if (this.serviceCenterPageEnabled || this.callWaiterButtonEnabled) {
      count++;
    }

    if (this.invoicePageEnabled) {
      count++;
    }

    return count;
  }

  private getFileData(url: string): SafeUrl | string {
    if (url.startsWith('data:')) {
      return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    return this.files$.getValue()[url] || '';
  }

  get logo(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.logo ?? '');
  }

  get idleDelay(): number {
    if (typeof environment.featureToggle.idleDelay === 'number') {
      return environment.featureToggle.idleDelay ?? 30;
    }
    return this.widgetUIConfig?.idleDelay ?? 30;
  }

  get tabBarAutoHide(): boolean {
    if (typeof environment.featureToggle.tabBarAutoHide === 'boolean') {
      return !!environment.featureToggle.tabBarAutoHide;
    }

    return this.widgetUIConfig?.tabBarAutoHide ?? false;
  }

  get primaryColor(): string {
    return this.widgetUIConfig?.colors.primary || '#8363EC';
  }

  get primaryColorLight(): string {
    return this.adjustColorLightness(this.primaryColor, 30);
  }

  get primaryColorDark(): string {
    return this.adjustColorLightness(this.primaryColor, -30);
  }

  get secondaryColor(): string {
    return this.widgetUIConfig?.colors.secondary || '#000000';
  }

  get secondaryColorLight(): string {
    return this.adjustColorLightness(this.secondaryColor, 30);
  }

  get secondaryColorDark(): string {
    return this.adjustColorLightness(this.secondaryColor, -30);
  }

  get tertiaryColor(): string {
    return this.widgetUIConfig?.colors.tertiary || '#FCFCFC';
  }

  get tertiaryColorLight(): string {
    return this.adjustColorLightness(this.tertiaryColor, 30);
  }

  get tertiaryColorDark(): string {
    return this.adjustColorLightness(this.tertiaryColor, -30);
  }

  get adPlayerBackgroundColor(): string {
    if (environment.backgroundAdPlayerColor) {
      return environment.backgroundAdPlayerColor ?? '#000000';
    }

    return this.tertiaryColor;
  }

  get callWaiterButtonEnabled(): boolean {
    return (
      environment.featureToggle.withCallWaiter
      && environment.featureToggle.withTabBar
      && (this.widgetUIConfig?.callWaiterButtons.callWaiter.enabled || false)
    );
  }

  get callWaiterButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons.callWaiter.name || '';
  }

  get callWaiterButtonLightColor(): RGBA | null {
    if (environment.featureToggle.ambientLight.callWaiter) {
      return environment.featureToggle.ambientLight.callWaiter;
    }

    if (this.widgetUIConfig?.callWaiterButtons.callWaiter.lightsColor) {
      return this.hexToRgba(this.widgetUIConfig.callWaiterButtons.callWaiter.lightsColor);
    }

    return null;
  }

  get anotherRoundButtonEnabled(): boolean {
    return (
      environment.featureToggle.withCallWaiterToRepeat
      && environment.featureToggle.withTabBar
      && (this.widgetUIConfig?.callWaiterButtons.anotherRound.enabled || false)
    );
  }

  get anotherRoundButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons.anotherRound.name || '';
  }

  get anotherRoundButtonLightColor(): RGBA | null {
    if (environment.featureToggle.ambientLight.callWaiter) {
      return environment.featureToggle.ambientLight.callWaiterToRepeat;
    }

    if (this.widgetUIConfig?.callWaiterButtons.anotherRound.lightsColor) {
      return this.hexToRgba(this.widgetUIConfig.callWaiterButtons.anotherRound.lightsColor);
    }

    return null;
  }

  get requestBillButtonEnabled(): boolean {
    return (
      environment.featureToggle.withCallWaiterToPay
      && environment.featureToggle.withTabBar
      && (this.widgetUIConfig?.callWaiterButtons.requestBill.enabled || false)
    );
  }

  get requestBillButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons.requestBill.name || '';
  }

  get requestBillButtonLightColor(): RGBA | null {
    if (environment.featureToggle.ambientLight.callWaiter) {
      return environment.featureToggle.ambientLight.callWaiterToPay;
    }

    if (this.widgetUIConfig?.callWaiterButtons.requestBill.lightsColor) {
      return this.hexToRgba(this.widgetUIConfig.callWaiterButtons.requestBill.lightsColor);
    }

    return null;
  }

  get menuPageEnabled(): boolean {
    return this.widgetUIConfig?.pages.menu.enabled || false;
  }

  get menuPageName(): string {
    return this.widgetUIConfig?.pages.menu.name || '';
  }

  get menuPageIcon(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.pages.menu.icon || '');
  }

  get serviceCenterPageEnabled(): boolean {
    return (
      environment.featureToggle.withServiceCentre
      && environment.featureToggle.withTabBar
      && (this.widgetUIConfig?.pages.serviceCenter.enabled || false)
    );
  }

  get serviceCenterPageName(): string {
    return this.widgetUIConfig?.pages.serviceCenter.name || '';
  }

  get serviceCenterPageIcon(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.pages.serviceCenter.icon || '');
  }

  get invoicePageEnabled(): boolean {
    return (
      environment.featureToggle.withInvoice
      && environment.featureToggle.withTabBar
      && (this.widgetUIConfig?.pages.invoice.enabled || false)
    );
  }

  get invoicePageName(): string {
    return this.widgetUIConfig?.pages.invoice.name || '';
  }

  get invoicePageIcon(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.pages.invoice.icon || '');
  }

  private hexToRgba(hex: string): RGBA {
    return {
      r: parseInt(hex.slice(1, 3), 16),
      g: parseInt(hex.slice(3, 5), 16),
      b: parseInt(hex.slice(5, 7), 16),
      a: 1
    };
  }

  public adjustColorLightness(color: string, amount: number): string {
    const colorHex = color.replace(/[^0-9a-f]/gi, '');
    const rgb = parseInt(colorHex, 16);
    // tslint:disable-next-line:no-bitwise
    const r = Math.min(255, Math.max(0, ((rgb >> 16) & 0xff) + amount));
    // tslint:disable-next-line:no-bitwise
    const g = Math.min(255, Math.max(0, ((rgb >> 8) & 0xff) + amount));
    // tslint:disable-next-line:no-bitwise
    const b = Math.min(255, Math.max(0, (rgb & 0xff) + amount));
    // tslint:disable-next-line:no-bitwise
    return '#' + (b | (g << 8) | (r << 16)).toString(16).padStart(6, '0');
  }

}
