import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-chalkboards',
  templateUrl: './chalkboards.component.html',
  styleUrls: ['./chalkboards.component.scss']
})
export class ChalkboardsComponent implements OnInit, OnDestroy{
  @Input() slides: string[] = [];
  imgUrl = '';
  currentIndex = 0;
  timeoutId: number | null = null;

  constructor() { }

  ngOnInit(): void {
  this.showSlide();
  }

  ngOnDestroy(): void {
    this.stopSlider();
  }

  showSlide(): void {
    this.imgUrl = this.slides[this.currentIndex];
    this.currentIndex = (this.currentIndex + 1) % this.slides.length;

    this.timeoutId = setTimeout(() => {
      this.showSlide();
    }, 5000);
  }

  stopSlider(): void {
    if (this.timeoutId){
      clearTimeout(this.timeoutId);
    }
  }
}
