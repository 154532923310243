import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {
  IMenuCategory,
  IProducts,
  ISubCategories
} from '../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class NewMenuStorageService {
  public isLoading = new BehaviorSubject(true);

  constructor(
    protected readonly db: NgxIndexedDBService,
  ) {}
  public addCategory(category: IMenuCategory): Observable<IMenuCategory> {
    console.log('ToAddCategory  ------<');
    return this.db.add('categories', category).pipe(
      tap((c) => console.log('ToAddCategory  ------>', c))
    );
  }

  public updateCategory(category: IMenuCategory): Observable<IMenuCategory | undefined> {
    return this.db.update('categories', category).pipe(
      switchMap(() => this.getCategory(category.id))
    );
  }

  public deleteCategory(category: IMenuCategory): Observable<IMenuCategory | undefined> {
    return this.db.deleteByKey('categories', category.id).pipe(
      map((deleted) => deleted ? category : undefined),
    );
  }

  public addSubCategories(subCategories: ISubCategories): Observable<ISubCategories> {
    console.log('ToAddSubCategories  +++++++<');
    return this.db.add('subCategories', subCategories).pipe(
      tap((d) => console.log(d))
    );
  }

  public updateSubCategories(subCategories: ISubCategories): Observable<ISubCategories | undefined> {
    return this.db.update('subCategories', subCategories).pipe(
      switchMap(() => this.getSubCategory(subCategories.id))
    );
  }

  public deleteSubCategories(subCategories: ISubCategories): Observable<ISubCategories | undefined> {
    return this.db.deleteByKey('subCategories', subCategories.id).pipe(
      map((deleted) => deleted ? subCategories : undefined),
    );
  }

  public addProduct(product: IProducts): Observable<IProducts> {
    console.log('ToAddProduct  ======<');
    return this.db.add('products', product);
  }

  public updateProduct(product: IProducts): Observable<IProducts | undefined> {
    return this.db.update('products', product).pipe(
      switchMap(() => this.getProduct(product.id))
    );
  }

  public deleteProduct(product: IProducts): Observable<IProducts | undefined> {
    return this.db.deleteByKey('products', product.id).pipe(
      map((deleted) => deleted ? product : undefined),
    );
  }

  public getSubCategory(id: number): Observable<ISubCategories> {
    return this.db.getByKey('subCategories', id);
  }

  public setProducts(product: IProducts): Observable<IProducts[]> {
    return this.db.update('products', product);
  }

  public getCategory(categoryID: number): Observable<IMenuCategory> {
    return this.db.getByKey('categories', categoryID);
  }

  public getAllCategory(): Observable<IMenuCategory[]> {
    return this.db.getAll('categories');
  }

  public getCategoriesByHierarchyId(hierarchyId: number): Observable<IMenuCategory[]> {
    return this.db.getAll<IMenuCategory>('categories').pipe(
      map((categories: IMenuCategory[]) => {
        return categories.filter(category => category.hierarchyId === hierarchyId);
      })
    );
  }

  public getCategorySubCategories(id: number): Observable<ISubCategories[]> {
    return this.getAllSub().pipe(
      map((subCategories: ISubCategories[]) => {
        return subCategories.filter(subCategory => subCategory.parentCategoryId === id);
      })
    );
  }

  public getProduct(productID: number): Observable<IProducts> {
    return this.db.getByKey('products', productID);
  }

  public getAllProducts(): Observable<IProducts[]> {
    return this.db.getAll('products');
  }

  public getAllSub(): Observable<ISubCategories[]> {
    return this.db.getAll('subCategories');
  }

  public getCategoryProducts(categoryID: number): Observable<IProducts[]> {
    return this.getAllProducts().pipe(
      map((products: IProducts[]) => {
        return products.filter(product => {
          if (Array.isArray(product.parentSubCategoryId)) {
            return product.parentSubCategoryId.indexOf(categoryID) >= 0;
          }
          return product.parentSubCategoryId === categoryID;
        });
      })
    );
  }
}
