
  <div class="product__preview" *ngIf="!hesImagError && product.sku !== 0 && productImage else noImage">
    <div class="product__preview__img" [class.loading]="loading" >
      <app-loading *ngIf="loading"></app-loading>
      <img [src]="productImage" alt="" (load)="onLoad()" (error)="onImageError($event)">
    </div>
    <div class="product__preview__info" *ngIf="!loading">
      <div class="name">{{ product.name }}</div>
      <div class="description">{{ product.description }}</div>
      <div class="product__info product__price">{{ product.productPrice }}</div>
    </div>
  </div>

  <ng-template #noImage>
    <div class="product__preview__info noImg">
      <div class="name">{{ product.name }}</div>
      <div class="description" *ngIf="!product.volume">{{ product.description }}</div>
      <div class="volumeBox" *ngIf="product.volume">
        <div class="volume" *ngFor="let vol of product.volume">
          <div class="volume__price">{{product.productPrice}}</div>
          <div class="volume__vol">{{ vol }}</div>
        </div>
      </div>
    </div>
  </ng-template>
