import { Directive, Inject, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { USER_ACTIVITY_SECTION } from '../services/click-tracking.service';

@Directive({
  selector: '[appActivitySection]',
  providers: [
    {
      provide: USER_ACTIVITY_SECTION,
      useFactory: () => new BehaviorSubject<string>('unknown')
    }
  ]
})
export class ActivitySectionDirective implements OnInit {
  @Input('appActivitySection') section!: string;

  constructor(
    @Inject(USER_ACTIVITY_SECTION) private sectionSubject: BehaviorSubject<string>
  ) {}

  ngOnInit(): void {
    this.sectionSubject.next(this.section);
  }
}
