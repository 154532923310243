import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, iif } from 'rxjs';
import { filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IKeyValue } from '../models';
import { AuthService } from './auth.service';
import { FileCacheService } from './file-cache.service';
import { KeyValueStorageService } from './key-value.storage.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class InvoiceQrcodeImageService {

  protected readonly urlStorageKey = 'invoiceQrcodeImage';

  public readonly url$ = new BehaviorSubject<SafeUrl | null>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly filesCache: FileCacheService,
    private readonly keyValueStorage: KeyValueStorageService,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.get().pipe(
      untilDestroyed(this),
    ).subscribe((safeUrl) => {
      this.url$.next(safeUrl);
    });

    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    });
  }

  public get(): Observable<SafeUrl> {
    const processSafeUrls = (url: string) => this.filesCache.getFile(url).pipe(
      map((file) => this.domSanitizer.bypassSecurityTrustUrl(
        file.objectUrl
      ))
    );

    return this.keyValueStorage.get(this.urlStorageKey).pipe(
      filter((entry): entry is IKeyValue => {
        return typeof entry !== 'undefined';
      }),
      pluck('value'),
      switchMap(processSafeUrls)
    );
  }

  public update(url: string): Observable<SafeUrl> {
    return this.clear().pipe(
      switchMap(() => this.keyValueStorage.set({
        key: this.urlStorageKey,
        value: url
      })),
      switchMap(() => this.get()),
      tap((safeUrl) => this.url$.next(safeUrl))
    );
  }

  public clear(): Observable<unknown> {
    return this.keyValueStorage.get(this.urlStorageKey).pipe(
      switchMap((entry) => iif(
        () => typeof entry !== 'undefined',
        this.filesCache.delete(entry?.value ?? null),
        of(entry)
      )),
      switchMap(() => {
        return this.keyValueStorage.delete(this.urlStorageKey);
      }),
      tap(() => this.url$.next(null))
    );
  }

}
