import { environment } from '../../../environments/environment';
import { mainPageAnimation, mainPageAnimation2, mainPageAnimationJA } from './main-page.animation';
import { OnInit, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { interval, timer, EMPTY, merge } from 'rxjs';
import { RGBA } from 'color-blend/dist/types';
import { switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IRestaurantTable } from '../../models';
import {
  AuthService,
  ConnectionStatusService,
  HappyHoursService,
  InvoiceQrcodeImageService,
  RestaurantTableService,
  MenusService,
  DWallIntercom,
  IdleStatusService,
  WidgetUiConfigService,
  WebsocketService
} from '../../services';

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: environment.featureToggle.theme === 'default'
    ? './main-page.component.html'
    : `../../../themes/${environment.featureToggle.theme}/pages/main-page/main-page.component.html`,
  styleUrls: ['./main-page.component.scss'],
  animations: [
    mainPageAnimation,
    mainPageAnimation2,
    mainPageAnimationJA
  ],
})
export class MainPageComponent implements OnInit {

  public confetti = false;

  constructor(
    private readonly changeRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly auth: AuthService,
    private readonly connectionStatusService: ConnectionStatusService,
    private readonly idleStatusService: IdleStatusService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
    private readonly happyHoursService: HappyHoursService,
    private readonly menusService: MenusService,
    private readonly invoiceQrcodeImageService: InvoiceQrcodeImageService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly webSocketService: WebsocketService,
  ) {}

  ngOnInit(): void {
    this.changeRef.detectChanges();

    this.initWorkerCallWaiter();
    this.initWorkerConfetti();
    this.initDWallIntercom();
  }

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get userInteracts(): boolean {
    return !this.idleStatusService.isIdle;
  }

  get isOnline(): boolean {
    return this.connectionStatusService.isOnline;
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get table(): IRestaurantTable | null {
    return this.restaurantTableService.table$.getValue();
  }

  get tableId(): number | null {
    return this.table?.tableId ?? null;
  }

  prepareRoute(outlet: RouterOutlet): any {
    return outlet?.activatedRouteData?.animation;
  }

  private initWorkerCallWaiter(): void {
    merge(
      this.restaurantTableService.callWaiter$,
      this.restaurantTableService.callWaiterToPay$,
      this.restaurantTableService.callWaiterToRepeat$,
    ).pipe(
      tap(() => this.webSocketService.sendCallWaiter()),
      tap(() => this.webSocketService.sendCallWaiterPushNotification()),
      tap(() => this.diSendCallWaiterAmbientLight()),
      switchMap(() =>
        (this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat)
          ? interval(5000).pipe(tap(() => this.webSocketService.sendCallWaiter()))
          : EMPTY
      ),
      untilDestroyed(this),
    ).subscribe();
  }

  private initWorkerConfetti(): void {
    this.happyHoursService.isNow$.pipe(
      tap((yes) => this.confetti = yes),
      switchMap(() => timer(4500)),
      tap(() => this.confetti = false),
      untilDestroyed(this),
    ).subscribe();
  }

  private initDWallIntercom(): void {
    this.dwallIntercom.messages$.pipe(
      untilDestroyed(this),
    ).subscribe((message) => {
      switch (message.event) {
        case 'playlist.items':
          this.webSocketService.sendPlaylistItems(message.data);
          break;
        case 'battery.level':
          this.webSocketService.sendBatteryLevel(+message.data.level, message.data.charging);
          break;
      }
    });
  }

  private diSendCallWaiterAmbientLight(): void {
    if (this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat) {
      let color: RGBA | null = null;

      if (this.callWaiter && this.widgetUi.callWaiterButtonLightColor) {
        color = this.widgetUi.callWaiterButtonLightColor;
      }

      if (this.callWaiterToRepeat && this.widgetUi.anotherRoundButtonLightColor) {
        color = this.widgetUi.anotherRoundButtonLightColor;
      }

      if (this.callWaiterToPay && this.widgetUi.requestBillButtonLightColor) {
        color = this.widgetUi.requestBillButtonLightColor;
      }

      if (color) {
        this.dwallIntercom.call('ambient_light.on', { color });
      }

    } else {
      this.dwallIntercom.call('ambient_light.off');
    }
  }

}
