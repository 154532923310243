import { Injectable } from '@angular/core';
import {fromEvent, merge, BehaviorSubject, timer, of} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {WidgetUiConfigService} from './widget-ui-config.service';
import { AuthService } from './auth.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class IdleStatusService {

  public readonly status$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly widgetUiConfigService: WidgetUiConfigService,
    private readonly auth: AuthService
  ) {
    merge(
      of('start'),
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'touchstart'),
      this.auth.logined$,
    ).pipe(
      tap(() => {
        if (this.status$.getValue()) {
          this.status$.next(false);
        }
      }),
      filter(() => this.idleDelay !== 0),
      switchMap(() => timer(this.idleDelay)),
      untilDestroyed(this),
    ).subscribe(() => {
      if (!this.status$.getValue()) {
        this.status$.next(true);
      }
    });
  }

  public get isIdle(): boolean {
    return this.status$.getValue();
  }

  get idleDelay(): number {
    if (this.widgetUiConfigService.idleDelay && this.widgetUiConfigService.idleDelay > 0) {
      return this.widgetUiConfigService.idleDelay * 1000;
    } else {
      return 0;
    }
  }
}
