import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, interval, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { animate, style, transition, trigger } from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-special-tab',
  templateUrl: './cbh-more-tab.component.html',
  styleUrls: ['./cbh-more-tab.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateY(0)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class CbhMoreTabComponent implements AfterViewInit {

  public slides: string[] = [
    '/themes/canadian-brewhouse/assets/images/more_1.png',
    '/themes/canadian-brewhouse/assets/images/more_2.png',
    '/themes/canadian-brewhouse/assets/images/more_3.png',
    '/themes/canadian-brewhouse/assets/images/more_1.png',
    '/themes/canadian-brewhouse/assets/images/more_2.png',
    '/themes/canadian-brewhouse/assets/images/more_3.png',
  ];
  public index = 0;
  public isAnimating = false;

  @ViewChild('content', { static: false })
  private content!: ElementRef;

  ngAfterViewInit(): void {
    fromEvent<TouchEvent>(this.content.nativeElement, 'touchstart').subscribe(startEvent => {
      const startY = startEvent.changedTouches[0].screenY;

      const touchEndSubscription = fromEvent<TouchEvent>(this.content.nativeElement, 'touchend').subscribe(endEvent => {
        const endY = endEvent.changedTouches[0].screenY;

        const direction = startY < endY
          ? 'down'
          : startY > endY
            ? 'up'
            : 'click';

        this.checkDirection(direction);

        touchEndSubscription.unsubscribe();
      });
    });
    interval(5000).pipe(
      takeUntil(merge(
        fromEvent(this.content.nativeElement, 'touchmove'),
        fromEvent(this.content.nativeElement, 'mousemove'),
        fromEvent(this.content.nativeElement, 'click'),
      )),
      untilDestroyed(this),
    ).subscribe(() => {
      if (!this.isAnimating) {
        this.getNext();
      }
    });
  }

  private checkDirection(direction: string): void {
    if (direction !== 'click') {
      direction === 'up'
        ? this.getNext()
        : this.getPrev();
    }
  }

  getPrev(): void {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.index = (this.index - 1 + this.slides.length) % this.slides.length;
      setTimeout(() => this.isAnimating = false, 300);
    }
  }

  getNext(): void {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.index = (this.index + 1) % this.slides.length;
      setTimeout(() => this.isAnimating = false, 300);
    }
  }
}
