import { environment } from '../../../environments/environment';
import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, iif, Subscription, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ConnectionStatusService, DWallIntercom, RestaurantTableService, WidgetUiConfigService } from '../../services';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-background-player',
  templateUrl: './background-player.component.html',
  styleUrls: ['./background-player.component.scss']
})
export class BackgroundPlayerComponent implements OnDestroy {
  public iframeUrl: SafeResourceUrl | null = null;

  private channelMessagesSubscription: Subscription | null = null;
  private intercomMessagesSubscription: Subscription | null = null;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly connectionStatus: ConnectionStatusService,
    private readonly intercom: DWallIntercom,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigService
  ) {
    const backgroundPlayer: string | null = environment.backgroundPlayer;
    const backgroundAdPlayer: string | null = environment.backgroundAdPlayer;

    const setIframeUrl = (url: string | null): void => {
      this.iframeUrl = url
        ? this.sanitizer.bypassSecurityTrustResourceUrl(url)
        : null;
    };

    const backgroundPlayerUrl$ = this.restaurantTableService.table$.pipe(
      map((table) => {
        const config: {
          type: 'order' | 'time',
          every: number,
          bgColor: string,
          debug: boolean,
          providers: {
            id: string,
            [key: string]: any
          }[]
        } = {
          type: 'order',
          every: table?.adRunEvery ?? 3,
          bgColor: this.widgetUiConfigService.adPlayerBackgroundColor,
          debug: !environment.production,
          providers: [],
        };

        if (table?.integrations?.length) {
          table?.integrations.map(integration => {

            if (integration.provider === 'VAST') {
              config.providers.push({
                id: 'VAST',
                tagUrl: integration.credentials.vastTagUrl
              });
            }

            if (integration.provider === 'vistarMedia') {
              config.providers.push({
                id: 'VistarMedia',
                apiKey: integration.credentials.apiKey,
                networkId: integration.credentials.networkId,
                venueId: integration.credentials.venueId
              });
            }
          });
        }
        return config;
      }),
      map((config) => {
        if (config.providers.length > 0) {
          return btoa(unescape(encodeURIComponent(JSON.stringify(config))));
        }

        return null;
      }),
      map((config) => {
        if (backgroundAdPlayer && config) {
          const url = new URL(backgroundAdPlayer);
          url.searchParams.set('config', config);
          return url.toString();
        }

        return backgroundPlayer;
      }),
      distinctUntilChanged(),
      untilDestroyed(this)
    );

    this.connectionStatus.status$.pipe(
      switchMap((status) => iif(
        () => status,
        backgroundPlayerUrl$,
        of(null)
      )),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((url) => {
      setIframeUrl(url);
    });

    fromEvent(window, 'unload').subscribe(() => {
      this.intercom.call('playlist.resume');
    });
  }

  private static debug(...params: any): void {
    if (!environment.production) {
      console.log('DWAll Intercom (BG Player Bridge) - ', ...params);
    }
  }

  public onLoadData(event: Event): void {
    const iframe = event.target;
    const channel = new MessageChannel();

    this.channelMessagesSubscription?.unsubscribe();
    this.intercomMessagesSubscription?.unsubscribe();

    // @ts-ignore
    iframe.contentWindow.postMessage(
      'dwall-handshake', '*', [channel.port2]
    );

    this.channelMessagesSubscription = fromEvent<MessageEvent>(
      channel.port1, 'message'
    ).subscribe((messageEvent) => {
      const message = JSON.parse(messageEvent.data);

      BackgroundPlayerComponent.debug('Received', message);

      this.intercom.call(message.method, message.data);
    });

    this.intercomMessagesSubscription = this.intercom.messages$.subscribe((message) => {
      BackgroundPlayerComponent.debug('Sent', message);

      channel.port1.postMessage(JSON.stringify(message));
    });

    channel.port1.start();
  }

  public onError(errorEvent: ErrorEvent): void {
    this.iframeUrl = null;
  }

  public ngOnDestroy(): void {
    this.intercom.call('playlist.resume');
  }

}
