import { environment } from '../../../environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: [environment.featureToggle.theme === 'default'
    ? './confetti.component.scss'
    : `../../../themes/${environment.featureToggle.theme}/components/confetti/confetti.component.scss`]
})
export class ConfettiComponent {}
