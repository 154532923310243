import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-qr-view',
  templateUrl: environment.featureToggle.theme === 'default'
    ? './qr-view.component.html'
    : `../../../themes/${environment.featureToggle.theme}/components/qr-view/qr-view.component.html`,
  styleUrls: [
    environment.featureToggle.theme === 'default'
      ?  './qr-view.component.scss'
      : `../../../themes/${environment.featureToggle.theme}/components/qr-view/qr-view.component.scss`,
  ]
})
export class QrViewComponent {

  @Input() title = '';
  @Input() imgSrc = '';
  @Input() description: string | undefined;
  @Input() description2: string | undefined;
  @Input() qrSrc: string | undefined;
  @Input() moreData: any | undefined;
  constructor() { }

}
