import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { fromArray, ISpecial, ISpecialRaw } from '../models/special.model';
import { iif, Observable, of } from 'rxjs';
import {map, mergeMap, switchMap, toArray} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileCacheService } from './file-cache.service';
import { DomSanitizer } from '@angular/platform-browser';

const collectionName = 'specials';


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SpecialsStorageService {

  constructor(
    protected readonly db: NgxIndexedDBService,
    private readonly auth: AuthService,
    private readonly filesCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    });
  }

  public gettAllSpecials(): Observable<ISpecial[]> {
    return this.getAll().pipe(
      switchMap((specials) => {
        return fromArray(specials).pipe(
          mergeMap((special) => {
            return this.filesCache.getFile(special.content).pipe(
              map(file => this.domSanitizer.bypassSecurityTrustUrl(file?.objectUrl)),
              map(file => ({ ...special, contentLocal: file }))
            );
          }),
          toArray(),
        );
      }),
    );
  }

  public clear(): Observable<unknown> {
    return this.getAll().pipe(
      switchMap(entry => iif(
        () => typeof entry !== 'undefined',
        this.filesCache.bulkDelete((entry ?? []).map((special: ISpecialRaw) => special.content)),
        of(entry)
      )),
      switchMap(() => {
        return this.db.clear(collectionName);
      }),
    );
  }

  public get(id: number): Observable<ISpecial | undefined> {
    return this.db.getByKey<ISpecial>(collectionName, id);
  }

  public add(value: ISpecial): Observable<ISpecial | undefined> {
    return this.db.add(collectionName, value).pipe(
      switchMap(() => this.get(value.id))
    );
  }

  public update(value: ISpecial): Observable<ISpecial | undefined> {
    return this.db.update<ISpecial>(collectionName, value).pipe(
      switchMap(() => this.get(value.id))
    );
  }

  public delete(special: ISpecial): Observable<ISpecial | undefined> {
    return this.db.deleteByKey(collectionName, special.id).pipe(
      map((deleted) => deleted ? special : undefined),
    );
  }

  public getAll(): Observable<ISpecial[]> {
    return this.db.getAll<ISpecial>(collectionName);
  }

}
