<div class="content" appActivitySection="CallWaiterButtons">
  <img class="logo" [src]="widgetUi.logo" alt="" />

  <button *ngIf="withCallWaiter && widgetUi.callWaiterButtonEnabled"
          [class.active]="callWaiter"
          (click)="handlerCallWaiter($event)"
          [appUserActivityClickTracking]="callWaiter ? 'CancelCallWaiter' : 'StartCallWaiter'"
  >
    {{ widgetUi.callWaiterButtonName }}
  </button>

  <button *ngIf="withCallWaiterToRepeat && widgetUi.anotherRoundButtonEnabled"
          [class.active]="callWaiterToRepeat"
          (click)="handlerCallWaiterToRepeat($event)"
          [appUserActivityClickTracking]="callWaiterToRepeat ? 'CancelCallWaiterToRepeat' : 'StartCallWaiterToRepeat'"
  >
    {{ widgetUi.anotherRoundButtonName }}
  </button>

  <div *ngIf="wifiName" class="box">
    <div class="box__title">WI-FI connection</div>

    <div class="box__field">
      <span class="box__field__label">Network:</span>
      <span class="box__field__value">{{ wifiName }}</span>
    </div>

    <div *ngIf="wifiPassword" class="box__field">
      <span class="box__field__label">Password:</span>
      <span class="box__field__value">{{ wifiPassword }}</span>
    </div>
  </div>
</div>
