import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthService } from './auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {forkJoin, from, Observable, of} from 'rxjs';
import { UserActivityLogs } from '../models/user-activity-logs.model';
import {map, mergeMap, tap} from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ActivityLogsStorageService {
  protected readonly collectionName = 'userActivity';

  constructor(
    protected readonly db: NgxIndexedDBService,
    private readonly auth: AuthService
  ) {
    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    });
  }

  add(value: UserActivityLogs): Observable<UserActivityLogs> {
    return this.db.add(this.collectionName, value);
  }

  getAll(): Observable<UserActivityLogs[]> {
    return from(this.db.getAll<UserActivityLogs>(this.collectionName));
  }

  getUnsent(): Observable<UserActivityLogs[]> {
    return from(this.db.getAll<UserActivityLogs>(this.collectionName)).pipe(
      map(logs => logs.filter(log => !log.sentAt))
    );
  }

  update(log: UserActivityLogs): Observable<UserActivityLogs[]> {
    return from(this.db.update<UserActivityLogs>(this.collectionName, log));
  }

  delete(id: number): Observable<void> {
    return from(this.db.delete(this.collectionName, id)).pipe(map(() => void 0));
  }

  getSent(): Observable<UserActivityLogs[]> {
    return from(this.db.getAll<UserActivityLogs>(this.collectionName)).pipe(
      map(logs => logs.filter(log => log.sentAt))
    );
  }

  clear(): Observable<void> {
    return from(this.db.clear(this.collectionName)).pipe(map(() => void 0));
  }
}
