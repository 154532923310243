import { Component } from '@angular/core';

@Component({
  selector: 'app-gift-tab',
  templateUrl: './gift-tab.component.html',
  styleUrls: ['./gift-tab.component.scss']
})
export class GiftTabComponent {

}
