import { Component, HostBinding } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { AuthService, DWallIntercom, RestaurantTableService, WebsocketService, WidgetUiConfigService } from './services';
import { IRestaurantTable } from './models';


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  public readonly loading$ = this.auth.loading$;

  @HostBinding('style.--theme-primary-color')
  private themePrimaryColor = this.widgetUiConfigService.primaryColor;
  @HostBinding('style.--theme-primary-light-color')
  private themePrimaryLightColor = this.widgetUiConfigService.primaryColorLight;
  @HostBinding('style.--theme-primary-dark-color')
  private themePrimaryDarkColor = this.widgetUiConfigService.primaryColorDark;
  @HostBinding('style.--theme-secondary-color')
  private themeSecondaryColor = this.widgetUiConfigService.secondaryColor;
  @HostBinding('style.--theme-secondary-light-color')
  private themeSecondaryLightColor = this.widgetUiConfigService.secondaryColorLight;
  @HostBinding('style.--theme-secondary-dark-color')
  private themeSecondaryDarkColor = this.widgetUiConfigService.secondaryColorDark;
  @HostBinding('style.--theme-tertiary-color')
  private themeTertiaryColor = this.widgetUiConfigService.tertiaryColor;
  @HostBinding('style.--theme-tertiary-light-color')
  private themeTertiaryLightColor = this.widgetUiConfigService.tertiaryColorLight;
  @HostBinding('style.--theme-tertiary-dark-color')
  private themeTertiaryDarkColor = this.widgetUiConfigService.tertiaryColorDark;

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly translate: TranslateService,
    private readonly websocketService: WebsocketService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigService
  ) {
    this.dwallIntercom.init();
    this.websocketService.init();
    this.websocketService.initDemo();

    this.translate.addLangs(['en-CA', 'fr-CA']);

    this.auth.logined$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.router.navigate(['main/home']);
    });

    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.router.navigate(['auth']);
    });

    this.restaurantTableService.table$.pipe(
      pluck('widgetUIConfig'),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(widgetUIConfig => {
      if (widgetUIConfig) {
        this.themePrimaryColor = this.widgetUiConfigService.primaryColor;
        this.themePrimaryLightColor = this.widgetUiConfigService.primaryColorLight;
        this.themePrimaryDarkColor = this.widgetUiConfigService.primaryColorDark;
        this.themeSecondaryColor = this.widgetUiConfigService.secondaryColor;
        this.themeSecondaryLightColor = this.widgetUiConfigService.secondaryColorLight;
        this.themeSecondaryDarkColor = this.widgetUiConfigService.secondaryColorDark;
        this.themeTertiaryColor = this.widgetUiConfigService.tertiaryColor;
        this.themeTertiaryLightColor = this.widgetUiConfigService.tertiaryColorLight;
        this.themeTertiaryDarkColor = this.widgetUiConfigService.tertiaryColorDark;
      }
    });

    this.restaurantTableService.getData().pipe(
      untilDestroyed(this),
      map((data: IRestaurantTable | null) => data?.language)
    ).subscribe(language => {
      this.translate.setDefaultLang(language ? language : 'en-CA');
    });
  }

}
