import { environment } from '../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { filter, find, map, switchMap } from 'rxjs/operators';
import { DWallIntercom, MenusService, RestaurantTableService } from '../../../services';
import { IMenu, IClickableMediaItem } from '../../../models';

@Component({
  selector: 'app-special-offer',
  templateUrl:
    environment.featureToggle.theme === 'default'
      ? './special-offer.component.html'
      : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/special-offer/special-offer.component.html`,
  styleUrls: [
    environment.featureToggle.theme === 'default'
      ? './special-offer.component.scss'
      : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/special-offer/special-offer.component.scss`,
  ],
})
export class SpecialOfferComponent implements OnInit {

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public readonly menu$ = new BehaviorSubject<IMenu|null>(null);

  constructor(
    private readonly router: Router,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly menusService: MenusService,
    private readonly dwallIntercom: DWallIntercom,
  ) {}

  get menu(): IMenu | null {
    return this.menu$.getValue();
  }

  ngOnInit(): void {
    const currentMedia = this.dwallIntercom.currentMedia$.getValue();

    if (currentMedia) {
      this.restaurantTableService.getData().pipe(
        map((table) => table.clickableMediaConfig),
        filter((data): data is IClickableMediaItem[] => data !== null),
        switchMap((data) => of(...data)),
        find((media): media is IClickableMediaItem => typeof media.menuId === 'number' && media.id === currentMedia.id),
        switchMap((media) => {
          return this.menusService.get().pipe(
            map((menus) => menus.find((menu) => menu.id === media?.menuId))
          );
        })
      ).subscribe((menu) => {
        if (menu) {
          this.menu$.next(menu);
        } else {
          this.router.navigate(['main/home']);
        }
      });
    } else {
      this.router.navigate(['main/home']);
    }
  }

}
