import { Component } from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-more-tab',
  templateUrl: './more-tab.component.html',
  styleUrls: ['./more-tab.component.scss']
})
export class MoreTabComponent {
  public isQRVisible = false;
  public title = '';
  public description: string | undefined;
  public imgSrc = '';

  prepareRoute(outlet: RouterOutlet): any {
    return outlet?.activatedRouteData?.animation;
  }

  showQRCode(title: string, imgSrc: string, description?: string): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;

    if (description) {
      this.description = description;
    }
  }

}
