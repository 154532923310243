<div class="content" appActivitySection="StoreGift">
  <div class="element"
       appUserActivityClickTracking="GiftCardsOpen"
       (click)="showQRCode(
       'Gift Cards',
       'Looking to give the gift of Brewhouse\n'+'to someone you care about? Look no further than our\n'+'online Gift Card delivery system! ',
       'Purchase a gift card for someone, email it to them, and\n'+'force them to use it on you the next time\n'+'you hit the town. It’s the perfect system! ',
       'themes/canadian-brewhouse/assets/images/gift_cards_pic.png',
       'themes/canadian-brewhouse/assets/images/qr_gift_cards.svg')"
  >
    <img src="themes/canadian-brewhouse/assets/images/gift_cards.png" alt="">
    <span>Gift cards</span>
  </div>

  <div class="element"
       appUserActivityClickTracking="MerchStoreOpen"
       (click)="showQRCode(
       'Merch store',
       'You can now rep brew vibes wherever you go! ',
       'Take a look at some of our featured products.',
       'themes/canadian-brewhouse/assets/images/merch_pic.png',
       'themes/canadian-brewhouse/assets/images/qr_merch_store.svg',
       true
       )"
  >
    <img class="card" src="themes/canadian-brewhouse/assets/images/merch_store.png" alt="">
    <span>Merch store</span>
  </div>

  <app-qr-view
    *ngIf="isQRVisible"
    (click)="isQRVisible = !isQRVisible"
    [title]="title"
    [imgSrc]="imgSrc"
    [qrSrc]="qrSrc"
    [description]="description"
    [description2]="description2"
    [moreData]="moreData"
  ></app-qr-view>
</div>
