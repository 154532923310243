import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileCacheModel, IFileCache } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FileCacheStorageService {

  protected readonly collectionName = 'fileCache';

  protected constructor(
    protected readonly db: NgxIndexedDBService,
  ) {}

  public add(fileUrl: string, fileBlob: Blob): Observable<FileCacheModel | undefined> {
    return this.db.add(this.collectionName, {
      url: fileUrl,
      blob: fileBlob,
      createdAt: Date.now(),
    }).pipe(
      map(entry => {
        if (typeof entry === 'object') {
          return new FileCacheModel(entry as IFileCache);
        }

        return undefined;
      })
    );
  }

  public get(fileUrl: string): Observable<FileCacheModel | undefined> {
    return this.db.getByKey(this.collectionName, fileUrl).pipe(
      map(entry => {
        if (typeof entry === 'object') {
          return new FileCacheModel(entry as IFileCache);
        }

        return undefined;
      })
    );
  }

  public bulkGet(filesUrl: string[]): Observable<IFileCache[]> {
    return this.db.bulkGet(this.collectionName, filesUrl);
  }

  public delete(fileUrl: string): Observable<unknown> {
    return this.db.delete(this.collectionName, fileUrl);
  }

  public bulkDelete(fileUrls: string[]): Observable<unknown> {
    return this.db.bulkDelete(this.collectionName, fileUrls);
  }

  public clear(): Observable<boolean> {
    return this.db.clear(this.collectionName);
  }

}
