import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, forkJoin, iif, concat } from 'rxjs';
import { filter, finalize, map, pluck, switchMap, tap, toArray } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IMenu, IMenuRaw, IKeyValue } from '../models';
import { AuthService } from './auth.service';
import { FileCacheService } from './file-cache.service';
import { KeyValueStorageService } from './key-value.storage.service';

const storageKey = 'restaurantMenus';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MenusService {

  public readonly loading$ = new BehaviorSubject<boolean>(true);

  public readonly menus$ = new BehaviorSubject<IMenu[]>([]);

  protected constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly filesCache: FileCacheService,
    private readonly keyValueStorage: KeyValueStorageService,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.loading$.next(true);

    this.get().pipe(
      untilDestroyed(this),
    ).subscribe((menus) => {
      this.menus$.next(menus);
      this.loading$.next(false);
    });

    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    });
  }

  public get(): Observable<IMenu[]> {
    const processFiles = (pages: string[]) => this.filesCache.getFiles(pages).pipe(
      map((files) => {
        return files.map((file) => this.domSanitizer.bypassSecurityTrustUrl(
          file.objectUrl
        ));
      }),
    );

    const processMenus = (menus: IMenu[]) => {
      return concat(
        ...menus.map((menu) => processFiles(menu.pages).pipe(
          map((files) => {
            return { ...menu, pagesLocal: files };
          })
        ))
      ).pipe(
        toArray(),
      );
    };

    const processIcons = (menus: IMenu[]): Observable<IMenu[]> => {
      return forkJoin(
        menus.map(menu => {
          if (menu.icon) {
            return this.filesCache.getFile(menu.icon).pipe(
              map(file => this.domSanitizer.bypassSecurityTrustUrl(file?.objectUrl)),
              map(file => ({ ...menu, iconLocal: file }))
            );
          }
          return of(menu);
        })
      );
    };

    return this.keyValueStorage.get(storageKey).pipe(
      filter((entry): entry is IKeyValue => {
        return typeof entry !== 'undefined';
      }),
      pluck('value'),
      switchMap(processMenus),
      switchMap(processIcons),
    );
  }

  public store(menus: IMenuRaw[]): Observable<IMenu[]> {
    return this.clear().pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => {
        return this.keyValueStorage.set({
          key: storageKey,
          value: menus,
        });
      }),
      switchMap(() => this.get()),
      tap((data) => this.menus$.next(data)),
      finalize(() => this.loading$.next(false)),
    );
  }

  public update(menus: IMenuRaw[]): Observable<IMenu[]> {
    const processOriginalMenus = this.keyValueStorage.get(storageKey).pipe(
      pluck('value'),
    );

    return forkJoin({
      original: processOriginalMenus,
      new: of(menus),
    }).pipe(
      filter((data) => (
        typeof data.original === 'undefined' || JSON.stringify(data.original) !== JSON.stringify(data.new)
      )),
      pluck('new'),
      switchMap((pages) => this.store(pages))
    );
  }

  public clear(): Observable<unknown> {
    return this.keyValueStorage.get(storageKey).pipe(
      switchMap(entry => iif(
        () => typeof entry !== 'undefined',
        this.filesCache.bulkDelete((entry?.value ?? []).map((menu: IMenuRaw) => menu.pages).flat()),
        of(entry)
      )),
      switchMap(() => {
        return this.keyValueStorage.delete(storageKey);
      }),
      tap(() => this.menus$.next([])),
    );
  }

}
