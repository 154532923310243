import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IDeviceWorkSchedule, IKeyValue } from '../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyValueStorageService } from './key-value.storage.service';
import { DWallIntercom } from './dwall-intercom';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import {AuthService} from './auth.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class DeviceWorkScheduleService {
  protected readonly deviceWorkScheduleKey = 'deviceWorkSchedule';
  private readonly deviceWorkSchedule: IDeviceWorkSchedule[] = [
    { active: false, day: 0, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 1, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 2, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 3, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 4, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 5, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 6, timeFrom: '00:00', timeTo: '00:00' }
  ];
  private schedule: IDeviceWorkSchedule[] = [];

  constructor(
    private readonly keyValueStorage: KeyValueStorageService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly authService: AuthService
  ) {
    this.dwallIntercom.messages$.pipe(
      filter((message) => message.event === 'device.work_schedule.failed'),
      debounceTime(5000),
      switchMap(() => this.keyValueStorage.get(this.deviceWorkScheduleKey)),
      untilDestroyed(this)
    ).subscribe((schedule) => {
      this.dwallIntercom.call('device.work_schedule', schedule?.value ?? this.deviceWorkSchedule);
    });

    this.authService.logined$.pipe(
      untilDestroyed(this),
      switchMap(() =>  this.keyValueStorage.get(this.deviceWorkScheduleKey)),
    ).subscribe(
      (schedule) => {
        if (schedule?.value) {
          this.schedule = this.deviceWorkSchedule.map(d => {
            const newDay = schedule.value.find((ell: IDeviceWorkSchedule) => d.day === ell.day);
            return newDay ? newDay : d;
          });
        }
        this.dwallIntercom.call('device.work_schedule', this.schedule.length > 0 ? this.schedule : this.deviceWorkSchedule);
        console.log('Intercom(init): device.work_schedule', this.schedule.length > 0 ? this.schedule : this.deviceWorkSchedule);
      }
    );
    this.authService.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(
      () => {
        this.dwallIntercom.call('device.work_schedule', this.deviceWorkSchedule);
        console.log('Intercom(logout): device.work_schedule', this.deviceWorkSchedule);
      }
    );
  }

  public syncDeviceWorkSchedule(schedule: IDeviceWorkSchedule[]): void {
    this.keyValueStorage.get(this.deviceWorkScheduleKey).pipe(
      map((entry) => entry?.value),
      switchMap((scheduleLocal) => {
        if (JSON.stringify(scheduleLocal) !== JSON.stringify(schedule)) {
          this.schedule = this.deviceWorkSchedule.map(d => {
            const newDay = schedule.find((ell: IDeviceWorkSchedule) => d.day === ell.day);
            if (newDay) {
              return {
                active: newDay.active,
                day: newDay.day,
                timeFrom: newDay.timeFrom.split(':').slice(0, 2).join(':'),
                timeTo: newDay.timeTo.split(':').slice(0, 2).join(':'),
              };
            } else {
              return {
                active: false,
                day: d.day,
                timeFrom: '00:00',
                timeTo: '00:00',
              };
            }
          });
          this.dwallIntercom.call('device.work_schedule', this.schedule);
          console.log('Intercom: device.work_schedule', this.schedule);
          return this.setWorkSchedule(this.schedule);
        } else {
          return of(schedule);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  public setWorkSchedule(schedule: IDeviceWorkSchedule[]): Observable<IKeyValue | undefined> {
    return this.keyValueStorage.delete(this.deviceWorkScheduleKey).pipe(
      switchMap(() => {
        return this.keyValueStorage.set({
          key: this.deviceWorkScheduleKey,
          value: schedule
        });
      })
    );
  }
}
