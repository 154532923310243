import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
  IMenuCategory, IProducts,
  ISubCategories,
  ISubCategoriesWithProducts
} from '../../../../app/models';
import { NewMenuStorageService } from '../../../../app/services/new-menu-storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { NewMenuApiService } from '../../../../app/services/new-menu-api.service';

@UntilDestroy()
@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss']
})
export class MenuCategoryComponent implements OnInit {
  public subCategories: ISubCategoriesWithProducts[] = [];
  public isSticky = false;

  constructor(
    private readonly storage: NewMenuStorageService,
    private readonly element: ElementRef,
  ) { }

  @Input() category!: IMenuCategory;
  @Input() isSolo!: boolean;
  @Output() closeCategory = new EventEmitter<boolean>();
  @Output() openProduct = new EventEmitter<IProducts>();

  ngOnInit(): void {
    this.initSubCategories();
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    this.isSticky = this.element.nativeElement.scrollTop >= 20;
  }

  private initSubCategories(): void {
    this.storage.getCategorySubCategories(this.category.id).pipe(
      switchMap((subCategories: ISubCategories[]) => {
        const subCategoryObservables = subCategories.map((subCategory: ISubCategories) => {
          return this.getProducts(subCategory.id).pipe(
            map((products: IProducts[]) => ({ ...subCategory, products }))
          );
        });

        return forkJoin(subCategoryObservables);
      }),
      untilDestroyed(this)
    ).subscribe((subCategories) => {
      this.subCategories = subCategories;
    });
  }

  public  emitClose(): void {
    this.subCategories = [];
    this.closeCategory.emit(true);
  }

  private getProducts(categoryID: number): Observable<IProducts[]> {
   return this.storage.getCategoryProducts(categoryID).pipe(
     untilDestroyed(this)
    );
  }

  showProduct(product: IProducts): void {
    this.openProduct.emit(product);
  }
}
