import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(
    private readonly http: HttpClient
  ) {}

  public downloadAsBlob(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: {
        'cache-control': 'no-cache, no-store, must-revalidate',
      }
    });
  }

  public downloadAsBase64(url: string): Observable<string> {
    return this.downloadAsBlob(url).pipe(
      switchMap(blob => {
        return this.blobToBase64(blob).pipe(
          map((base64: string) => base64)
        );
      })
    );
  }

  public blobToBase64(blob: Blob): Observable<any> {
    const fileReader = new FileReader();
    const observable = new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
    fileReader.readAsDataURL(blob);

    return observable;
  }

}
