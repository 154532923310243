<ng-container *ngIf="!loading">
  <div class="types" appActivitySection="SportType">
    <button
      class="type"
      [class.type--active]="(type$ | async) === 'ice_hockey'"
      (click)="selectType('ice_hockey')"
      appUserActivityClickTracking="SelectIceHockey"
    >
      <svg class="type__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" version="1.1">
        <path d="M178.88 911.04a224 80 0 1 0 448 0 224 80 0 1 0-448 0Z" fill="currentColor"/>
        <path d="M864 32l-116.48 340.8c-34.88 88.32-104 133.44-188.8 125.44L152.96 459.52a101.76 101.76 0 0 0-111.04 109.76l14.08 169.6A101.76 101.76 0 0 0 157.44 832h21.12v96h448v-96h9.92a179.84 179.84 0 0 0 171.52-125.44L992 160zM402.88 832c-123.84 0-224-35.84-224-80s100.16-80 224-80 224 35.84 224 80-100.48 80-224 80z" fill="currentColor"/>
      </svg>
      <span class="type__name">Ice Hockey</span>
    </button>
    <button
      class="type"
      [class.type--active]="(type$ | async) === 'baseball'"
      (click)="selectType('baseball')"
      appUserActivityClickTracking="SelectBaseball"
    >
      <svg class="type__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" version="1.1">
        <path d="M739.84 841.28l48-83.2a305.92 305.92 0 0 0 75.2 32 448 448 0 0 0-203.84-700.8l-2.56-2.56A446.4 446.4 0 0 0 128 281.92a512 512 0 0 1 67.52 66.56l-73.6 61.76a424.64 424.64 0 0 0-35.52-37.44A448 448 0 0 0 787.52 864a419.2 419.2 0 0 1-47.68-22.72z m-582.08-384l80.64-51.84a617.28 617.28 0 0 1 49.6 96l-88.32 37.12a520.96 520.96 0 0 0-42.24-80z m108.16 125.12l46.08-12.8a643.52 643.52 0 0 1 21.44 105.6l-96 11.2a545.92 545.92 0 0 0-18.24-88.96z m61.76 271.68l-94.08-18.56a462.72 462.72 0 0 0 8.64-89.28h96a560 560 0 0 1-10.88 107.84zM544 82.24l81.28 51.2a352 352 0 0 0-35.2 75.2l-90.88-32A448 448 0 0 1 544 82.24z m-71.68 264.96A552.64 552.64 0 0 1 480 245.44l96 17.6a455.68 455.68 0 0 0-7.68 84.16h-96z m24.64 169.6l10.24-2.88-10.24 2.88a621.44 621.44 0 0 1-20.8-100.8l96-11.52a524.16 524.16 0 0 0 17.28 84.16l-81.92 24.32 36.48-9.28z m22.72 64L608 544a498.88 498.88 0 0 0 39.68 76.48L567.04 672a595.2 595.2 0 0 1-47.36-90.56zM608 728.32l73.92-61.12a417.92 417.92 0 0 0 60.16 59.52L681.6 800a515.2 515.2 0 0 1-73.6-71.68z" fill="currentColor"/>
      </svg>
      <span class="type__name">Baseball</span>
    </button>
    <button
      class="type"
      [class.type--active]="(type$ | async) === 'basketball'"
      (click)="selectType('basketball')"
      appUserActivityClickTracking="SelectBasketball"
    >
      <svg class="type__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" version="1.1">
        <path d="M691.2 341.76a489.6 489.6 0 0 0 268.8 145.92 445.44 445.44 0 0 0-98.24-257.92 1152 1152 0 0 0-178.88 102.08zM142.4 416a308.48 308.48 0 0 0-69.44 8 448 448 0 0 0 82.56 358.4 1515.52 1515.52 0 0 1 219.52-290.56A428.8 428.8 0 0 0 142.4 416z m660.16-246.08A445.44 445.44 0 0 0 550.4 64a463.36 463.36 0 0 0 81.6 205.12 1280 1280 0 0 1 170.56-99.2z m-369.6 263.68a1702.72 1702.72 0 0 1 134.72-116.16 539.84 539.84 0 0 1-96-252.16 448 448 0 0 0-372.8 273.6 499.52 499.52 0 0 1 334.08 94.72z m200.96-35.84c-5.76-5.76-10.88-12.16-16.32-18.24-40.32 32-81.28 66.24-121.92 104.64 7.68 7.04 15.36 13.76 22.72 21.44A523.84 523.84 0 0 1 675.2 928a448 448 0 0 0 281.28-360.64h-8.32a569.6 569.6 0 0 1-314.24-169.92z m-195.2 143.04a1414.72 1414.72 0 0 0-226.24 302.72 448 448 0 0 0 377.28 109.12c32-120.96-14.72-273.92-128-390.72-8-8-15.68-14.4-23.04-21.44z" fill="currentColor"/>
      </svg>
      <span class="type__name">Basketball</span>
    </button>
  </div>

  <div class="matches" appActivitySection="Matches">
    <div *ngIf="!matches.length" class="matches__empty">No matches</div>

    <div *ngFor="let match of matches"
         class="match"
         [class.match--active]="match.id === currentMatch?.id"
         (click)="selectMatch(match)"
         appUserActivityClickTracking="SelectMatch-{{match.home_team.name}}-{{match.away_team.name}}"
    >
      <div class="match__time">{{ match.date.toLocaleTimeString() }}</div>
      <div class="match__teams">
        <div class="match__team match__team--home">
          <span class="match__team__logo">
            <img [src]="match.home_team.logo" [alt]="match.home_team.name" width="20" height="20">
          </span>
          <span class="match__team__name">{{ match.home_team.name }}</span>
        </div>
        <div class="match__team match__team--away">
          <span class="match__team__logo">
            <img [src]="match.away_team.logo" [alt]="match.away_team.name" width="20" height="20">
          </span>
          <span class="match__team__name">{{ match.away_team.name }}</span>
        </div>
      </div>
      <div class="match__score">
<!--        <span class="match__score__home">{{ match.scores.ft['0'] }}</span>-->
<!--        <span class="match__score__away">{{ match.scores.ft['1'] }}</span>-->
      </div>
    </div>
  </div>

  <iframe
    *ngIf="currentWidgetUrl"
    [src]="currentWidgetUrl"
  ></iframe>
</ng-container>

<app-loading *ngIf="loading"></app-loading>
