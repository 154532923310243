import { environment } from '../../../../environments/environment';
import { Component, AfterContentInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { SafeUrl } from '@angular/platform-browser';
import {
  ConnectionStatusService,
  InvoiceQrcodeImageService,
  RestaurantTableService,
  WebsocketService,
  WidgetUiConfigService
} from '../../../services';

@UntilDestroy()
@Component({
  selector: 'app-invoice-tab',
  templateUrl: environment.featureToggle.theme === 'default'
    ? './invoice-tab.component.html'
    : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/invoice-tab/invoice-tab.component.html`,
  styleUrls: [
    environment.featureToggle.theme === 'default'
      ? './invoice-tab.component.scss'
      : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/invoice-tab/invoice-tab.component.scss`
  ]
})
export class InvoiceTabComponent implements AfterContentInit {

  qrUrl: SafeUrl | null = null;
  tableName: string | null = null;

  constructor(
    private readonly webSocketService: WebsocketService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly invoiceQrcodeImageService: InvoiceQrcodeImageService,
    private readonly connectionStatusService: ConnectionStatusService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
  ) {}

  ngAfterContentInit(): void {
    this.restaurantTableService.table$.pipe(
      map(table => table?.tableName ?? null),
      untilDestroyed(this),
    ).subscribe((tableName) => {
      this.tableName = tableName;
    });

    this.invoiceQrcodeImageService.url$.pipe(
      untilDestroyed(this),
    ).subscribe((grUrl) => {
      this.qrUrl = grUrl;
    });
  }

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get isOnline(): boolean {
    return this.connectionStatusService.isOnline;
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get withCallWaiterToPayFeatures(): boolean {
    const withCallWaiterToPay = this.restaurantTableService.table$.getValue()?.features?.withCallWaiterToPay;

    if (withCallWaiterToPay !== undefined) {
      return withCallWaiterToPay;
    }
    return true;
  }

  get withCallWaiterToPay(): boolean {
    return environment.featureToggle.withCallWaiterToPay && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiter$.next(!this.callWaiter);
    }
  }

  handlerCallWaiterToPay($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToPay$.next(!this.callWaiterToPay);
    }
  }

  handlerCallWaiterToRepeat($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToRepeat$.next(!this.callWaiterToRepeat);
    }
  }

}
