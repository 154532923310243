import { Component } from '@angular/core';

@Component({
  selector: 'app-get-rewarded-tab',
  templateUrl: './get-rewarded-tab.component.html',
  styleUrls: ['./get-rewarded-tab.component.scss']
})
export class GetRewardedTabComponent {
  public isQRVisible = false;
  public title = '';
  public description: string | undefined;
  public imgSrc = '';

  showQRCode(title: string, imgSrc: string, description?: string): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;

    if (description) {
      this.description = description;
    }
  }
}
