import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { of, Observable, EMPTY, merge } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { arrayDiff, arrayDiffIdCompare, fromArray, ISpecial } from '../models/special.model';
import { FileCacheService } from './file-cache.service';
import { SpecialsStorageService } from './specials.storage.service';
interface ToUpdate<T> {
  origin: T;
  value: T;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SpecialsService {

  constructor(
    private readonly filesCache: FileCacheService,
    private readonly specialsStorage: SpecialsStorageService,
  ) {}

  private toAdd(specials: ISpecial[]): Observable<ISpecial[]> {
   return fromArray(specials).pipe(
      mergeMap((special) => this.specialsStorage.add(special)),
      mergeMap((special) => {
        if (!special) {
          return EMPTY;
        }

        return this.filesCache.getFile(special.content).pipe(
          map(() => special)
        );
      }),
      toArray()
    );
  }

  private toUpdate(specials: ToUpdate<ISpecial>[]): Observable<ISpecial[]>{
    return fromArray(specials).pipe(
      mergeMap(({ origin, value }) => this.specialsStorage.update(value).pipe(
        map((special) => ({
          origin,
          special,
        }))
      )),
      mergeMap(({origin, special}) => {
        if (!special) {
          return EMPTY;
        }

        if (special.content === origin.content) {
          return of(special);
        }

        return this.filesCache.delete(origin.content).pipe(
          mergeMap(() => this.filesCache.getFile(special.content)),
          map(() => special)
        );
      }),
      toArray()
    );
  }

  private toDelete(specials: ISpecial[]): Observable<ISpecial[]> {
   return fromArray(specials).pipe(
      mergeMap((special) => this.specialsStorage.delete(special)),
      mergeMap((special) => {
        if (!special) {
          return EMPTY;
        }

        return this.filesCache.delete(special.content).pipe(
          map(() => special)
        );
      }),
      toArray(),
    );
  }

  public syncSpecials(specialsWS: ISpecial[]): Observable<ISpecial[]> {
    return  this.specialsStorage.getAll().pipe(
      switchMap((currentSpecials ) => {
        const add = arrayDiff(specialsWS, currentSpecials, arrayDiffIdCompare);
        const del = arrayDiff(currentSpecials, specialsWS, arrayDiffIdCompare);
        const upd = currentSpecials.reduce<ToUpdate<ISpecial>[]>((acc, origin) => {
          const value = specialsWS.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }

          return acc;
        }, []);

        return merge(
          this.toAdd(add),
          this.toUpdate(upd),
          this.toDelete(del),
        );
      })
    );
  }
}
