import { environment } from '../../../../environments/environment';
import { homePageAnimations } from './home-page.animation';
import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {EMPTY, interval, of, timer} from 'rxjs';
import {filter, find, map, switchMap, tap} from 'rxjs/operators';
import { IClickableMediaItem } from '../../../models';
import { ConnectionStatusService, DWallIntercom, IdleStatusService, MenusService, RestaurantTableService } from '../../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-home-tab',
  templateUrl: environment.featureToggle.theme === 'default'
    ? './home-tab.component.html'
    : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/home-tab/home-tab.component.html`,
  styleUrls: [environment.featureToggle.theme === 'default'
    ? './home-tab.component.scss'
    : `../../../../themes/${environment.featureToggle.theme}/pages/main-page/home-tab/home-tab.component.scss`],
  animations: [
    homePageAnimations
  ]
})
export class HomeTabComponent {
  public showAds = false;

  constructor(
    private readonly router: Router,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly menusService: MenusService,
    private readonly idleStatusService: IdleStatusService,
    private readonly networkConnection: ConnectionStatusService,
    private readonly dwallIntercom: DWallIntercom
  ) {
    this.initShowAds();
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get isHomePage(): boolean {
    return this.router.url === '/main/home';
  }

  specialOfferOnClick(): void {
    const currentMedia = this.dwallIntercom.currentMedia$.getValue();

    if (currentMedia) {
      this.restaurantTableService.getData().pipe(
        map((table) => table.clickableMediaConfig),
        filter((data): data is IClickableMediaItem[] => data !== null),
        switchMap((data) => of(...data)),
        find((media): media is IClickableMediaItem => typeof media.menuId === 'number' && media.id === currentMedia.id),
        switchMap((media) => {
          return this.menusService.get().pipe(
            map((menus) => menus.find((menu) => menu.id === media?.menuId))
          );
        })
      ).subscribe((menu) => {
        if (menu) {
          this.router.navigate(['main/home/special-offer']);
        }
      });
    }
  }

  initShowAds(): void {
    this.idleStatusService.status$.pipe(
      tap((idle) => {
        if (!idle && this.showAds) {
          this.showAds = false;
        }
      }),
      switchMap((idle) => {
        if (idle && this.networkConnection.status$.getValue()) {
          return interval(40000).pipe(
            tap(() => this.showAds = true),
            switchMap(() => timer(10000)),
            tap(() => this.showAds = false),
          );
        }

        return EMPTY;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  prepareRoute(outlet: RouterOutlet): any {
    return outlet?.activatedRouteData?.animation;
  }

}
