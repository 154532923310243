import { Observable } from 'rxjs';

export interface IFileCache {
  url: string;
  blob: Blob;
  createdAt: number;
}

export class FileCacheModel implements IFileCache {
  public url: string;
  public blob: Blob;
  public createdAt: number;

  constructor(data: IFileCache) {
    this.url = data.url;
    this.blob = data.blob;
    this.createdAt = data.createdAt;
  }

  public get base64(): Observable<any> {
    const fileReader = new FileReader();
    const observable = new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
    fileReader.readAsDataURL(this.blob);

    return observable;
  }

  public get objectUrl(): string {
    return URL.createObjectURL(this.blob);
  }
}
