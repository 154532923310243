import {Directive, HostListener, Inject, Input, OnInit} from '@angular/core';
import { ClickTrackingService, USER_ACTIVITY_SECTION } from '../services/click-tracking.service';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {ScreenTrackingService} from '../services/screen-tracking.service';

@Directive({
  selector: '[appUserActivityClickTracking]'
})
export class UserActivityClickTrackingDirective implements OnInit {
  @Input('appUserActivityClickTracking') target!: string;
  private section = '';
  private screen = '';

  constructor(
    @Inject(USER_ACTIVITY_SECTION) private sectionSubject: BehaviorSubject<string>,
    private screenTrackingService: ScreenTrackingService,
    private readonly clickTrackingService: ClickTrackingService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.sectionSubject,
      this.screenTrackingService.screen$
    ]).subscribe(([section, screen]) => {
      this.section = section;
      this.screen = screen;
    });
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent): void {
    event.preventDefault();
    this.clickTrackingService.trackClick(event, this.screen, this.section, this.target);
  }
}
