import { Component } from '@angular/core';

type State = 'categories' | 'tv' | 'chalkboards' | 'sport';

@Component({
  selector: 'app-watch-tab',
  templateUrl: './watch-tab.component.html',
  styleUrls: ['./watch-tab.component.scss']
})
export class WatchTabComponent {

  public videos: string[] = [
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv000.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv001.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv002.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv003.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv004.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv005.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv006.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv007.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv008.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv009.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv010.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv011.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv012.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv013.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv014.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv015.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv016.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv017.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv018.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv019.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv020.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv021.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv022.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv023.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv024.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv025.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv026.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv027.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv028.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv029.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv030.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv031.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv032.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv033.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv034.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv035.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv036.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv037.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv038.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv039.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv040.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv041.mp4',
    'https://oongalee-excuse-me.s3.ca-central-1.amazonaws.com/assets/jack-astors-static/video/tv042.mp4'
  ];

  public slides = [
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-01.jpg',
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-02.jpg',
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-03.jpg',
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-04.jpg',
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-05.jpg',
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-06.jpg',
    '/themes/jack-astors/assets/images/watch/chalkboards/Oongalee_Chalkboards_800x1280_0723-07.jpg'
  ];

  public state: State = 'categories';

  public get isCategories(): boolean {
    return this.state === 'categories';
  }

  public get isTv(): boolean {
    return this.state === 'tv';
  }

  public get isChalkboards(): boolean {
    return this.state === 'chalkboards';
  }

  public get isSport(): boolean {
    return this.state === 'sport';
  }

  public setState(state: State): void {
    this.state = state;
  }

}
