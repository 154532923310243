import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, merge, of, Subscription, timer } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, share, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { Router } from '@angular/router';
import { ConnectionStatusService, AuthService } from '../../services';

const defaultWidgetCode = '0000';

@UntilDestroy()
@Component({
  selector: 'app-auth',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {

  public widgetCode: string = defaultWidgetCode;

  private readonly webSocket$ = webSocket({
    url: `${environment.socketApi}/ws/auth`,
    openObserver: {
      next: () => console.log('WebSocket Auth: Open')
    },
    closingObserver: {
      next: () => {
        console.log('WebSocket Auth: Closing');
        this.clearWidgetCode();
      }
    },
    closeObserver: {
      next: () => {
        console.log('WebSocket Auth: Close');
        this.clearWidgetCode();
      }
    }
  });

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly connectionStatusService: ConnectionStatusService,
    private readonly auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.initWorkerWebSocket();
  }

  get isOnline(): boolean {
    return this.connectionStatusService.isOnline;
  }

  get isDefaultWidgetCode(): boolean {
    return this.widgetCode === defaultWidgetCode;
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public handlerChangeLanguage(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      this.translate.use(event.target.value);
    }
  }

  private initWorkerWebSocket(): void {
    let wsSub: Subscription | null = null;
    let timeoutSub: Subscription | null = null;
    let heartbeatSub: Subscription | null = null;

    const unsubscribeAll = () => {
      wsSub?.unsubscribe();
      wsSub = null;
      timeoutSub?.unsubscribe();
      timeoutSub = null;
      heartbeatSub?.unsubscribe();
      heartbeatSub = null;
    };

    const initWebSocket = () => {
      unsubscribeAll();

      const share$ = this.webSocket$.pipe(
        filter((response: any) => !!(response?.type)),
        catchError((error) => {
          console.error('WebSocket Auth:', error);
          return EMPTY;
        }),
        share(),
      );

      timeoutSub = merge(of('init'), share$).pipe(
        switchMap(() => of('timeout').pipe(delay(10000))),
        untilDestroyed(this),
      ).subscribe((msg) => {
        console.log(`WebSocket Auth: ${ msg }`);

        initWebSocket();
      });

      heartbeatSub = timer(1000, 5000).pipe(
        untilDestroyed(this),
      ).subscribe(() => {
        console.log('WebSocket Auth: Heartbeat send');
        this.webSocket$.next({ type: 'heartbeat' });
      });

      wsSub = share$.pipe(
        untilDestroyed(this),
      ).subscribe(
        (response: any) => this.wsOnMessage(response),
        (error) => {
          console.error('WebSocket Auth:', error);

          initWebSocket();
        },
        () => console.log('WebSocket Auth: Complete'),
      );
    };

    this.connectionStatusService.status$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe((status) => {
      if (status) {
        console.log('Connection status: online');
        initWebSocket();
      } else {
        console.warn('Connection status: offline');
        unsubscribeAll();
      }
    });
  }

  private wsOnMessage(response: any): void {
    switch (response.type) {
      case 'widgetCode':
        this.widgetCode = response.data.widgetCode;
        break;

      case 'jwt':
        this.webSocket$.complete();
        this.auth.login(response.data.JWT);
        break;

      case 'heartbeat':
        console.log('WebSocket Auth received: Heartbeat', response.data?.time);
        break;

      default:
        console.warn('WebSocket Auth: Unknown message', response);
        break;
    }
  }

  private clearWidgetCode(): void {
    this.widgetCode = defaultWidgetCode;
  }

}
