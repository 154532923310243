import { environment } from '../../../environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: [environment.featureToggle.theme === 'default'
    ? './loading.component.scss'
    : `../../../themes/${environment.featureToggle.theme}/components/loading/loading.component.scss`]
})
export class LoadingComponent {}
