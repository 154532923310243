import { Component } from '@angular/core';

@Component({
  selector: 'app-app-tab',
  templateUrl: './app-tab.component.html',
  styleUrls: ['./app-tab.component.scss']
})
export class AppTabComponent {
  public isQRVisible = false;
  public qrSrc = '';


  showQRCode(qrSrc: string): void {
    this.isQRVisible = true;
    this.qrSrc = qrSrc;
  }
}
