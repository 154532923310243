import {Observable, MonoTypeOperatorFunction, fromEvent, timer, EMPTY, from} from 'rxjs';
import { filter, delayWhen, delay } from 'rxjs/operators';

export const webSocketRetryStrategy = () => (attempts: Observable<any>) => {
  return attempts.pipe(
    delayWhen(() => {
      if (window.navigator.onLine) {
        console.warn('WebSocket: Retrying in 3s.');
        return timer(3000);
      } else {
        console.warn('WebSocket: Retrying when going online.');
        return fromEvent(window, 'online').pipe(delay(3000));
      }
    })
  );
};

export const filterEmpty = <T>(): MonoTypeOperatorFunction<T> => {
  return filter<T>(value => value === undefined || value === null);
};

export const filterNotEmpty = <T>(): MonoTypeOperatorFunction<T> => {
  return filter<T>(value => value !== undefined && value !== null);
};

export const arrayDiff = <T>(
  arr1: T[],
  arr2: T[],
  compare: (a: T, b: T) => boolean = (a, b) => a === b,
) => arr1.filter(element => !arr2.some((value) => {
  return compare(value, element);
}));
export const arrayDiffIdCompare = <T extends { id: number }>(a: T, b: T) => a.id === b.id;

export const fromArray = <T>(items: T[]) => items.length === 0 ? EMPTY : from(items);
