import { Component } from '@angular/core';

@Component({
  selector: 'app-cbh-store-tab',
  templateUrl: './cbh-store-tab.component.html',
  styleUrls: ['./cbh-store-tab.component.scss']
})
export class CbhStoreTabComponent {
  isQRVisible = false;
  title = '';
  description = '';
  description2 = '';
  qrSrc = '';
  imgSrc = '';
  moreData: any | undefined;

  showQRCode(title: string, description: string, description2: string, imgSrc: string, qrSrc: string, moreData?: any): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;
    this.qrSrc = qrSrc;
    this.description = description;
    this.description2 = description2;
    this.moreData = moreData ? moreData : undefined;
  }
}
