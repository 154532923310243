<div *ngIf="widgetUi.toolbarButtonsCount > 1" class="levitation" [class.active]="hideTabBar">
  <div class="tab-bar tab-bar__with-bg" [style.--indicator-position.px]="tabIndicator.position">
    <div class="indicator" [class.accent]="tabIndicator.accent && this.isHomePage" [class.pulse]="tabIndicatorPulse" appActivitySection="TabBarIndicator">
      <div *ngIf="widgetUi.menuPageEnabled"
           class="label"
           [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
           [class.active]="isMenuPage"
           [appUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
      >
        {{ widgetUi.menuPageName }}
      </div>
      <div *ngIf="widgetUi.serviceCenterPageEnabled"
           class="label" routerLink="/main/home"
           routerLinkActive="active"
           (click)="navigateTo(isServicePage ? ['/main/home'] : ['/main/home/service'])"
           [appUserActivityClickTracking]="isServicePage ? 'ServicePageClose' : 'ServicePageOpen'"
      >
        <span>{{ widgetUi.serviceCenterPageName }}</span>
      </div>
      <div *ngIf="withCallWaiter && widgetUi.callWaiterButtonEnabled && !widgetUi.serviceCenterPageEnabled"
           class="label"
           routerLink="/main/home"
           routerLinkActive="active"
           (click)="handlerCallWaiter($event)"
           [appUserActivityClickTracking]="callWaiter ? 'CancelCallWaiter' : 'StartCallWaiter'"
      >
        <span *ngIf="!callWaiter">{{ widgetUi.callWaiterButtonName }}</span>
        <span *ngIf="callWaiter">{{ 'tabBar.callWaiterCancel' | translate }}</span>
      </div>
      <div *ngIf="(!withCallWaiter || !widgetUi.callWaiterButtonEnabled) && !widgetUi.serviceCenterPageEnabled"
           class="label"
           routerLink="/main/home"
           routerLinkActive="active"
           appUserActivityClickTracking="GoHomePage"
      >
        <img class="logo" [src]="widgetUi.logo" alt=""/>
      </div>
      <div *ngIf="widgetUi.invoicePageEnabled"
           class="label"
           [routerLink]="isInvoicePage ? '/main/home' : '/main/invoice'"
           [class.active]="isInvoicePage"
           [appUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
      >
        {{ widgetUi.invoicePageName }}
      </div>
    </div>

    <div class="tabs" #tabs appActivitySection="TabBar">
      <button *ngIf="widgetUi.menuPageEnabled" class="tab" routerLink="/main/menu" routerLinkActive="active" appUserActivityClickTracking="OpenMenuPage">
        <img *ngIf="widgetUi.menuPageIcon" [src]="widgetUi.menuPageIcon" [alt]="widgetUi.menuPageName" />
        <span>{{ widgetUi.menuPageName }}</span>
      </button>
      <button *ngIf="widgetUi.serviceCenterPageEnabled" class="tab" indicatorAccent routerLink="/main/home" routerLinkActive="active" appUserActivityClickTracking="GoHomePage">
        <img *ngIf="widgetUi.serviceCenterPageIcon" [src]="widgetUi.serviceCenterPageIcon" alt="" />
        <span>{{ widgetUi.serviceCenterPageName }}</span>
      </button>
      <button *ngIf="withCallWaiter && widgetUi.callWaiterButtonEnabled && !widgetUi.serviceCenterPageEnabled" class="tab" indicatorAccent routerLink="/main/home" routerLinkActive="active" appUserActivityClickTracking="GoHomePage">
        <img *ngIf="widgetUi.logo" [src]="widgetUi.logo" alt="" />
        <span>{{ widgetUi.callWaiterButtonName }}</span>
      </button>
      <button *ngIf="(!withCallWaiter || !widgetUi.callWaiterButtonEnabled) && !widgetUi.serviceCenterPageEnabled" class="tab" routerLink="/main/home" routerLinkActive="active" appUserActivityClickTracking="GoHomePage">
        <img *ngIf="widgetUi.logo" [src]="widgetUi.logo" alt=""/>
      </button>
      <button *ngIf="widgetUi.invoicePageEnabled" class="tab" routerLink="/main/invoice" routerLinkActive="active" appUserActivityClickTracking="OpenInvoicePage">
        <img *ngIf="widgetUi.invoicePageIcon" [src]="widgetUi.invoicePageIcon" [alt]="widgetUi.invoicePageName"/>
        <span>{{ widgetUi.invoicePageName }}</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="widgetUi.toolbarButtonsCount === 1" class="levitation" [class.active]="hideTabBar">
  <div class="tab-bar" [style.--indicator-position.%]="85">
    <div class="indicator" [class.accent]="tabIndicator.accent && this.isHomePage" [class.pulse]="tabIndicatorPulse" appActivitySection="TabBarIndicator">
      <ng-container *ngIf="widgetUi.menuPageEnabled">
        <div class="label"
             routerLink="/main/menu"
             routerLinkActive="active"
             (click)="navigateTo(['main/home'])"
             [appUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
        >
          <img class="logo" [src]="widgetUi.logo" alt=""/>
        </div>

        <div class="label"
             routerLink="/main/home"
             routerLinkActive="active"
             (click)="navigateTo(['main/menu'])"
             [appUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
        >
          {{ widgetUi.menuPageName }}
        </div>
      </ng-container>

      <div *ngIf="widgetUi.serviceCenterPageEnabled"
           class="label" routerLink="/main/home"
           routerLinkActive="active"
           (click)="navigateTo(isServicePage ? ['/main/home'] : ['/main/home/service'])"
           [appUserActivityClickTracking]="isServicePage ? 'ServicePageClose' : 'ServicePageOpen'"
      >
        {{ widgetUi.serviceCenterPageName }}
      </div>

      <div *ngIf="withCallWaiter && widgetUi.callWaiterButtonEnabled && !widgetUi.serviceCenterPageEnabled"
           class="label" routerLink="/main/home"
           routerLinkActive="active" (click)="handlerCallWaiter($event)"
           [appUserActivityClickTracking]="callWaiter ? 'CancelCallWaiter' : 'StartCallWaiter'"
      >
        <span *ngIf="!callWaiter">{{ widgetUi.callWaiterButtonName }}</span>
        <span *ngIf="callWaiter">{{ 'tabBar.callWaiterCancel' | translate }}</span>
      </div>

      <ng-container *ngIf="widgetUi.invoicePageEnabled">
        <div class="label"
             routerLink="/main/invoice"
             routerLinkActive="active"
             (click)="navigateTo(['main/home'])"
             [appUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
        >
          <img class="logo" [src]="widgetUi.logo" alt=""/>
        </div>

        <div class="label"
             routerLink="/main/home"
             routerLinkActive="active"
             (click)="navigateTo(['main/invoice'])"
             [appUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
        >
          {{ widgetUi.invoicePageName }}
        </div>
      </ng-container>
    </div>

    <div class="tabs tabs__center" #tabs appActivitySection="TabBar">
      <button *ngIf="widgetUi.menuPageEnabled && isMenuPage"
              class="tab"
              routerLink="/main/menu"
              routerLinkActive="active"
              indicatorAccent
              appUserActivityClickTracking="OpenMenuPage"
      >
        <img class="logo" [src]="widgetUi.logo" alt=""/>
      </button>
      <button *ngIf="isHomePage"
              class="tab"
              routerLink="/main/home"
              routerLinkActive="active"
              indicatorAccent
              appUserActivityClickTracking="GoHomePage"
      >
        <img class="logo" [src]="widgetUi.logo" alt=""/>
      </button>
      <button *ngIf="widgetUi.invoicePageEnabled && isInvoicePage"
              class="tab"
              routerLink="/main/invoice"
              routerLinkActive="active"
              indicatorAccent
              appUserActivityClickTracking="OpenInvoicePage"
      >
        <img class="logo" [src]="widgetUi.logo" alt=""/>
      </button>
    </div>
  </div>
</div>
<div class="interacts-present" *ngIf="showInteractsPresent">
  <ng-lottie
    [options]="{path: '/assets/animations/touch.json'}"
  ></ng-lottie>
</div>
<div class="interacts-present-bottom"
     *ngIf="!hideTabBar"
>
  <span class="jump">Touch the screen to interact</span>
  <div class="interacts-present-bottom-line"></div>
</div>
