import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HappyHoursModel, IHappyHours } from '../models';

@Injectable({
  providedIn: 'root'
})
export class HappyHoursStorageService {

  protected readonly collectionName = 'happyHours';

  protected constructor(
    protected readonly db: NgxIndexedDBService,
  ) {}

  public set(value: IHappyHours): Observable<HappyHoursModel | undefined> {
    return this.db.update(this.collectionName, value).pipe(
      switchMap(() => this.get(value.day)),
    );
  }

  public get(day: number): Observable<HappyHoursModel | undefined> {
    return this.db.getByKey(this.collectionName, day).pipe(
      map(entry => {
        if (typeof entry === 'object') {
          return new HappyHoursModel(entry as HappyHoursModel);
        }

        return undefined;
      })
    );
  }

  public getAll(): Observable<IHappyHours[]> {
    return this.db.getAll(this.collectionName).pipe(
      map((entries) => {
        if (typeof entries === 'object') {
          return entries.map((entry) => new HappyHoursModel(entry as HappyHoursModel));
        }

        return [];
      })
    );
  }

  public clear(): Observable<boolean> {
    return this.db.clear(this.collectionName);
  }

}
