<div class="container" appActivitySection="App">
  <div class="container__image">
      <img src="/themes/original-joe/assets/images/appImg.jpg" alt="">
  </div>
  <div class="container__description">
    <span>Get closer to us</span>
  </div>
  <div class="container__links">
    <img (click)="showQRCode('/themes/original-joe/assets/images/appStoreQr.png')"
         src="/themes/original-joe/assets/images/appStore.svg" alt="appStore"
         appUserActivityClickTracking="ShowAppStoreQR"
    >
    <img (click)="showQRCode('/themes/original-joe/assets/images/googleQr.png')"
         src="/themes/original-joe/assets/images/google.svg" alt="googlePlay"
         appUserActivityClickTracking="ShowGooglePlayQR"
    >
  </div>

  <app-qr-view
    *ngIf="isQRVisible"
    (click)="isQRVisible = !isQRVisible"
    [qrSrc]="qrSrc"
  ></app-qr-view>
</div>
