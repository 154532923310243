import { Component } from '@angular/core';

@Component({
  selector: 'app-play-tab',
  templateUrl: './play-tab.component.html',
  styleUrls: ['./play-tab.component.scss']
})
export class PlayTabComponent {
  isQRVisible = false;
  gameName = '';
  imgSrc = '';

  showQRCode(name: string, imgSrc: string): void {
    this.isQRVisible = true;
    this.gameName = name;
    this.imgSrc = imgSrc;
  }
}
