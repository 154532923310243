import { environment } from '../../../environments/environment';
import { Component, ElementRef, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, fromEvent, interval, merge, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, skip, switchMap, tap } from 'rxjs/operators';
import {
  ConnectionStatusService,
  IdleStatusService,
  RestaurantTableService,
  WebsocketService,
  WidgetUiConfigService
} from '../../services';

@UntilDestroy()
@Component({
  selector: 'app-tab-bar',
  templateUrl:  environment.featureToggle.theme === 'default'
    ? './tab-bar.component.html'
    : `../../../themes/${environment.featureToggle.theme}/components/tab-bar/tab-bar.component.html`,
  styleUrls: [
    environment.featureToggle.theme === 'default'
      ? './tab-bar.component.scss'
      : `../../../themes/${environment.featureToggle.theme}/components/tab-bar/tab-bar.component.scss`,
  ]
})
export class TabBarComponent implements OnInit, AfterViewInit {

  public readonly tabIndicator = {
    position: -100,
    accent: false,
  };

  public showInteractsPresent = false;

  @ViewChild('tabs')
  private tabsElement: ElementRef | undefined;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly webSocketService: WebsocketService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly networkConnection: ConnectionStatusService,
    private readonly idleStatusService: IdleStatusService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
  ) {}

  ngOnInit(): void {
    this.initWorkerConnectionStatus();
    this.initWorkerUserInteracts();
  }

  ngAfterViewInit(): void {
    this.initWorkerIndicatorPosition();
  }

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get userInteracts(): boolean {
    return !this.idleStatusService.isIdle;
  }

  get hideTabBar(): boolean {
    if (this.widgetUiConfigService.tabBarAutoHide) {
      return !this.idleStatusService.isIdle;
    } else {
      return true;
    }
  }

  get isOnline(): boolean {
    return this.networkConnection.status$.getValue();
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get show(): boolean {
    return true;
  }

  get tabIndicatorPulse(): boolean {
    return this.tabIndicator.accent && (this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat);
  }

  get featureToggle(): {
    withTabBar: boolean,
    withCallWaiter: boolean,
    withCallWaiterToPay: boolean,
    withCallWaiterOnOffline: boolean,
    withInvoice: boolean,
    withServiceCentre: boolean
  } {
    return environment.featureToggle;
  }

  get withCallWaiter(): boolean {
    return environment.featureToggle.withCallWaiter && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterToPay(): boolean {
    return environment.featureToggle.withCallWaiterToPay && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterToRepeat(): boolean {
    return environment.featureToggle.withCallWaiterToRepeat && (
      environment.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withTabBar(): boolean {
    return environment.featureToggle.withTabBar;
  }

  get withInvoice(): boolean {
    return environment.featureToggle.withInvoice;
  }

  get withServiceCentre(): boolean {
    return environment.featureToggle.withServiceCentre;
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get isHomePage(): boolean {
    return this.router.url === '/main/home';
  }

  get isMenuPage(): boolean {
    return this.router.url === '/main/menu' || this.router.url === `/main/${environment.featureToggle.theme}/menu`;
  }

  get isInvoicePage(): boolean {
    return this.router.url === '/main/invoice' || this.router.url === `/main/${environment.featureToggle.theme}/invoice`;
  }

  get isPlayPage(): boolean {
    return this.router.url === '/main/jack-astors/play';
  }

  get isGetRewardedPage(): boolean {
    return this.router.url === `/main/${environment.featureToggle.theme}/rewarded`;
  }

  get isWatchPage(): boolean {
    return this.router.url === '/main/jack-astors/watch';
  }

  get isMorePage(): boolean {
    return this.router.url === `/main/${environment.featureToggle.theme}/more`;
  }

  get isCharityPage(): boolean {
    return this.router.url === '/main/the-keg/charity';
  }

  get isStorePage(): boolean {
    return this.router.url === `/main/${environment.featureToggle.theme}/store`;
  }

  get isAppPage(): boolean {
    return this.router.url === `/main/${environment.featureToggle.theme}/app`;
  }

  get isGiftPage(): boolean {
    return this.router.url === '/main/original-joe/gift';
  }

  get  isSpecialPage(): boolean {
    return this.router.url === '/main/kelseys/special';
  }

  get isServicePage(): boolean {
    return this.router.url === '/main/home/service';
  }

  handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiter$.next(!this.callWaiter);
    }
  }

  navigateTo(commands: any[]): void {
    this.router.navigate(commands);
  }

  private initWorkerIndicatorPosition(): void {
    timer(1000).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });

    this.router.events.pipe(
      filter((event): event is NavigationStart => {
        return event instanceof NavigationStart;
      }),
      untilDestroyed(this),
    ).subscribe((event) => {
      this.setIndicatorPositionByUrl(event.url);
    });

    fromEvent(window, 'resize').pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });

    this.restaurantTableService.table$.pipe(
      pluck('widgetUIConfig'),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(() => {
      this.router.navigate(['/main/home']);

      setInterval(() => this.setIndicatorPositionByUrl(this.router.url), 500);
    });
  }

  private initWorkerConnectionStatus(): void {
    merge(
      this.networkConnection.status$,
      this.webSocketService.connected$,
    ).pipe(
      map(() => this.withCallWaiter),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe((status) => {
      this.tabIndicator.accent = this.isHomePage && status;

      if (!status) {
        this.restaurantTableService.stopAllCallWaiter();
      }

      if (!status && this.isServicePage) {
        this.router.navigate(['/main/home']);
      }
    });
  }

  private initWorkerUserInteracts(): void {
    this.idleStatusService.status$.pipe(
      skip(1),
      tap((idle) => {
        if (!idle && this.isHomePage) {
          this.router.navigate(environment.featureToggle.showAfterClick);
        }
      }),
      filter((idle) => idle),
      untilDestroyed(this),
    ).subscribe(() => {
      if (!this.isHomePage) {
        this.router.navigate(['/main/home']);
      }

      if (this.translate.currentLang !== this.translate.defaultLang) {
        this.translate.use(this.translate.defaultLang);
      }
    });

    merge(
      this.restaurantTableService.table$.pipe(
        map(() => ({
          idleDelay: this.widgetUiConfigService.idleDelay,
          tabBarAutoHide: this.widgetUiConfigService.tabBarAutoHide
        })),
        distinctUntilChanged(),
        tap(({ tabBarAutoHide }) => {
          if (!tabBarAutoHide) {
            this.showInteractsPresent = false;
          }
        }),
        map(() => this.idleStatusService.isIdle)
      ),
      this.idleStatusService.status$
    ).pipe(
      tap((idle) => {
        if (!idle && this.showInteractsPresent) {
          this.showInteractsPresent = false;
        }
      }),
      switchMap((idle) => {
       if (idle && this.widgetUiConfigService.tabBarAutoHide) {
         return interval(39700).pipe(
           tap(() => this.showInteractsPresent = true),
           switchMap(() => timer(9700)),
           tap(() => this.showInteractsPresent = false),
         );
       }

       return EMPTY;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  private setIndicatorPositionByUrl(url: string): void {
    const element = this.getTabElementByUrl(url);

    if (element) {
      this.tabIndicator.position = element.offsetLeft + (element.offsetWidth / 2);
      this.tabIndicator.accent = element.hasAttribute('indicatorAccent');
    }
  }

  private getTabElementByUrl(url: string): HTMLElement | null {
    return this.tabsElement?.nativeElement.querySelector(`.tab[routerlink="${url}"]`);
  }

}
