import { NgModule } from '@angular/core';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'excuse-me.offline',
  version: 1,
  objectStoresMeta: [
    {
      store: 'fileCache',
      storeConfig: { keyPath: 'url', autoIncrement: false },
      storeSchema: [
        { name: 'url', keypath: 'url', options: { unique: true } },
        { name: 'blob', keypath: 'blob', options: { unique: false } },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } }
      ]
    },
    {
      store: 'happyHours',
      storeConfig: { keyPath: 'day', autoIncrement: false },
      storeSchema: [
        { name: 'day', keypath: 'day', options: { unique: true } },
        { name: 'timeFrom', keypath: 'timeFrom', options: { unique: false } },
        { name: 'timeTo', keypath: 'timeTo', options: { unique: false } },
        { name: 'localTimeFrom', keypath: 'localTimeFrom', options: { unique: false } },
        { name: 'localTimeTo', keypath: 'localTimeTo', options: { unique: false } }
      ]
    },
    {
      store: 'specials',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'ownerType', keypath: 'ownerType', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'scheduleStart', keypath: 'scheduleStart', options: { unique: false } },
        { name: 'scheduleEnd', keypath: 'scheduleEnd', options: { unique: false } },
        { name: 'sequence', keypath: 'sequence', options: { unique: false } },
        { name: 'content', keypath: 'content', options: { unique: false } },
        { name: 'updatedAt', keypath: 'updatedAt', options: { unique: false } },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } }
      ]
    },
    {
      store: 'keyValue',
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [
        { name: 'key', keypath: 'key', options: { unique: true } },
        { name: 'value', keypath: 'value', options: { unique: false } }
      ]
    },
    {
      store: 'userActivity',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'action', keypath: 'action', options: { unique: false } },
        { name: 'target', keypath: 'target', options: { unique: false } },
        { name: 'screen', keypath: 'screen', options: { unique: false } },
        { name: 'section', keypath: 'section', options: { unique: false } },
        { name: 'clientX', keypath: 'clientX', options: { unique: false } },
        { name: 'clientY', keypath: 'clientY', options: { unique: false } },
        { name: 'capturedAt', keypath: 'capturedAt', options: { unique: false } },
        { name: 'sentAt', keypath: 'sentAt', options: { unique: false } }
      ]
    },
    {
      store: 'categories',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'categoryName', keypath: 'categoryName', options: { unique: false } },
        { name: 'subCategories', keypath: 'subCategories', options: { unique: false } },
        { name: 'hierarchyId', keypath: 'hierarchyId', options: { unique: false } },
      ]
    },
    {
      store: 'subCategories',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'categoryName', keypath: 'categoryName', options: { unique: false } },
        { name: 'parentCategoryId', keypath: 'parentCategoryId', options: { unique: false } },
      ]
    },
    {
      store: 'products',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'sku', keypath: 'sku', options: { unique: false } },
        { name: 'showImage', keypath: 'showImage', options: { unique: false } },
        { name: 'productPrice', keypath: 'productPrice', options: { unique: false } },
        { name: 'isVegetarian', keypath: 'isVegetarian', options: { unique: false } },
        { name: 'description', keypath: 'description', options: { unique: false } },
        { name: 'calories', keypath: 'calories', options: { unique: false } },
        { name: 'allergenList', keypath: 'allergenList', options: { unique: false } },
        { name: 'upgrades', keypath: 'upgrades', options: { unique: false } },
        { name: 'upsellItem', keypath: 'upsellItem', options: { unique: false } },
        { name: 'customFields', keypath: 'customFields', options: { unique: false } },
        { name: 'parentSubCategoryId', keypath: 'parentSubCategoryId', options: { unique: false } },
        { name: 'hierarchyId', keypath: 'hierarchyId', options: { unique: false } },
        { name: 'volume', keypath: 'volume', options: { unique: false } },
      ]
    },
  ]
};

@NgModule({
  imports: [NgxIndexedDBModule.forRoot(dbConfig)],
  exports: [NgxIndexedDBModule],
})
export class AppDatabaseModule { }
