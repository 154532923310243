import { Component } from '@angular/core';

@Component({
  selector: 'app-charity-tab',
  templateUrl: './charity-tab.component.html',
  styleUrls: ['./charity-tab.component.scss']
})
export class CharityTabComponent {

}
