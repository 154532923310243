<div class="content" appActivitySection="CategoryGrid">
  <div class="element"
       (click)="showQRCode(
       'Experience the keg at home',
       'The legendary taste of The Keg, at home. From seasonings and basting sauces to our much-loved dressings and burgers, bring quality Keg products to your kitchen.',
       'themes/the-keg/assets/images/products.png',
       'themes/the-keg/assets/images/prodQr.png')"
       appUserActivityClickTracking="ShowProductsQR"
  >
    <img src="themes/the-keg/assets/images/products.png" alt="">
    <span>Retail products</span>
  </div>

  <div class="element"
       (click)="showQRCode(
       'Gift cards',
       'Give your colleagues, friends, or family the gift of good food, good company, and good times with a Keg gift card.',
       'themes/the-keg/assets/images/card.jpg',
       'themes/the-keg/assets/images/cardQr.png')"
       appUserActivityClickTracking="ShowGiftCardQR"
  >
    <img class="card" src="themes/the-keg/assets/images/card.jpg" alt="">
    <span>Gift <br> cards</span>
  </div>

  <app-qr-view
    *ngIf="isQRVisible"
    (click)="isQRVisible = !isQRVisible"
    [title]="title"
    [imgSrc]="imgSrc"
    [qrSrc]="qrSrc"
    [description]="description"
  ></app-qr-view>
</div>
