import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of} from 'rxjs';
import { RestaurantTableService } from './restaurant-table.service';
import { catchError, map, switchMap, tap} from 'rxjs/operators';
import { IIntegrationKelseyMenu, IMenuConfig, IMenuGCategory } from '../models';
import { environment } from '../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class NewMenuApiService {
  private menuConfig = new BehaviorSubject<IMenuConfig>({
    apiBasePath: '',
    storeNumber: 0,
    lang: '',
    priceType: '',
    proxyApiProvider: '',
    proxyCdnProvider: 'kelseys-menu-cdn'
  });
  public menuGCategory = new BehaviorSubject<IMenuGCategory[]>([]);

  constructor(
    private readonly http: HttpClient,
    private readonly restaurantTableService: RestaurantTableService,
  ) {
    this.getMenuIntegration().pipe(
      untilDestroyed(this)
    ).subscribe();
  }

  private getMenuIntegration(): Observable<void> {
    return this.restaurantTableService.table$.pipe(
      tap((table) => {

        if (table?.integrations?.length) {
          const integration: IIntegrationKelseyMenu = table.integrations.find(
            int => int.provider === 'kelseysMenu'
          ) as IIntegrationKelseyMenu;

          if (integration) {
            this.menuConfig.next({
              apiBasePath: integration.credentials.apiBasePath,
              storeNumber: integration.credentials.storeNumber,
              lang: integration.credentials.lang,
              priceType: integration.credentials.priceType,
              proxyApiProvider: integration.credentials.proxyApiProvider,
              proxyCdnProvider: integration.credentials.proxyCdnProvider
            });
            this.menuGCategory.next(integration.credentials.categories);
          } else {
            this.menuGCategory.next([]);
            this.resetConfig();
          }
        } else {
          this.menuGCategory.next([]);
          this.resetConfig();
        }
      }),
      switchMap(() => of(void 0)),
      catchError(err => {
        console.error(err);
        this.menuGCategory.next([]);
        this.resetConfig();
        return of(void 0);
      })
    );
  }

  private resetConfig(): void {
    this.menuConfig.next({
      apiBasePath: '',
      storeNumber: 0,
      lang: '',
      priceType: '',
      proxyApiProvider: '',
      proxyCdnProvider: 'kelseys-menu-cdn'
    });
  }

  public getCategory(hierarchyId: number): Observable<any> {
    const config = this.menuConfig.getValue();
    const url = `@api_host/proxy/${config.proxyApiProvider}/${config.apiBasePath}?storeNumber=${config.storeNumber}&hierarchyID=${hierarchyId}&priceType=${config.priceType}&lang=${config.lang}`;
    return this.http.get(url).pipe(
      map((response: any) => response.response?.responseContent?.categories || []),
    );
  }

  public getImgUrl(sku: number): string {
    const config = this.menuConfig.getValue();
    return `${environment.apiUrl}/proxy/${config.proxyCdnProvider}/static_images/kelseys2x/750x564/${sku}@2x.jpg?impolicy=imgopt&imwidth=400`;
  }

  public getImg(sku: number): Observable<Blob> {
    return this.http.get(this.getImgUrl(sku), {responseType: 'blob'});
  }
}
