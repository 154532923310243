import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, timer, concat } from 'rxjs';
import { tap, toArray, filter, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from './auth.service';
import { HappyHoursStorageService } from './happy-hours.storage.service';
import { HappyHoursModel, HappyHoursNextModel, HappyHoursNowModel, IHappyHours, IHappyHoursRaw } from '../models';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class HappyHoursService {

  public readonly timetable$ = new BehaviorSubject<IHappyHours[]>([]);

  public readonly status$ = new BehaviorSubject<HappyHoursNextModel|HappyHoursNowModel|null>(null);

  constructor(
    private readonly auth: AuthService,
    private readonly storage: HappyHoursStorageService
  ) {
    this.initTimetable();
    this.initLogouted();
    this.initStatus();
  }

  private initTimetable(): void {
    this.gatAll().pipe(
      untilDestroyed(this),
    ).subscribe((timetable) => {
      this.timetable$.next(timetable);
    });
  }

  private initLogouted(): void {
    this.auth.logouted$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    });
  }

  private initStatus(): void {
    this.timetable$.pipe(
      switchMap((times) => {
        if (times.length !== 0) {
          return this.atHappyHour(times);
        }
        return of(null);
      }),
      untilDestroyed(this),
    ).subscribe((status) => {
      this.status$.next(status);
    });
  }

  get isNow$(): Observable<boolean> {
    return this.status$.pipe(
      map((hh) => hh instanceof HappyHoursNowModel),
      distinctUntilChanged(),
    );
  }

  public gatAll(): Observable<IHappyHours[]> {
    return this.storage.getAll();
  }

  public updateByCollection(timetable: IHappyHoursRaw[]): Observable<HappyHoursModel[]> {
    return this.gatAll().pipe(
      filter((value) => {
        const newValue = timetable.map(HappyHoursModel.fromRaw);

        return JSON.stringify(value) !== JSON.stringify(newValue);
      }),
      switchMap(() => this.storage.clear()),
      switchMap(() =>
        concat(
          ...timetable.map((hh) => this.storage.set(HappyHoursModel.fromRaw(hh)).pipe(
            filter((entry): entry is HappyHoursModel => {
              return typeof entry !== 'undefined';
            }),
          ))
        ).pipe(
          toArray()
        )
      ),
      tap((value) => this.timetable$.next(value))
    );
  }

  public clear(): Observable<boolean> {
    return this.storage.clear().pipe(
      tap(() => this.timetable$.next([]))
    );
  }

  private atHappyHour(happyHours: IHappyHours[]): Observable<HappyHoursNextModel|HappyHoursNowModel|null> {
    return timer(0, 1000).pipe(
      map(() => {
        const currentDate: Date = new Date();
        const [day, currentHours] = [
          currentDate.getDay(),
          currentDate.getHours(),
          currentDate.getMinutes(),
        ];

        return happyHours.filter((hh) =>
          hh.day === day
          && currentHours <= hh.timeTo.hours
        );
      }),
      map((candidates) => {
        const happyHourNow = candidates.find((hh) => {
          const currentDate = moment();
          const dateFrom = HappyHoursModel.hhTimeToDate(hh.timeFrom);
          const dateTo = HappyHoursModel.hhTimeToDate(hh.timeTo);

          return currentDate.isSameOrAfter(dateFrom) && currentDate.isSameOrBefore(dateTo);
        });

        if (happyHourNow) {
          return new HappyHoursNowModel(happyHourNow);
        }

        const happyHourNext = candidates.find((hh) => {
          const currentDate = moment();
          const dateFrom = HappyHoursModel.hhTimeToDate(hh.timeFrom);
          const hoursBeforeDateFrom = dateFrom.clone().subtract(60, 'minutes');

          return currentDate.isSameOrAfter(hoursBeforeDateFrom) && currentDate.isBefore(dateFrom);
        });

        if (happyHourNext) {
          return new HappyHoursNextModel(happyHourNext);
        }

        return null;
      })
    );
  }
}
