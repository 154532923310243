import { Injectable } from '@angular/core';
import { fromEvent, merge, of, BehaviorSubject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ConnectionStatusService {

  public readonly status$ = new BehaviorSubject<boolean>(true);

  constructor() {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
    ).pipe(
      untilDestroyed(this),
    ).subscribe((status) => {
      this.status$.next(status);
    });
  }

  public get isOnline(): boolean {
    return this.status$.getValue();
  }

}
