import { Injectable } from '@angular/core';
import {
  IConfigurationOptions,
  IConfigurations,
  IConfigurationsRaw,
  IMenuCategory,
  IMenuCategoryRaw,
  IMenuGCategory,
  IProcessCategory, IProcessSubCategories,
  IProducts,
  IProductsRaw, ISubCategories,
  ISubCategoriesRaw,
  IUpsellItem,
  IUpsellItemRaw
} from '../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {EMPTY, forkJoin, from, merge, Observable, of} from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  repeatWhen,
  retryWhen,
  switchMap,
  tap,
  toArray
} from 'rxjs/operators';
import { NewMenuStorageService } from './new-menu-storage.service';
import { arrayDiff } from '../shared/rxjs-utils';
import { ConnectionStatusService } from './connection-status.service';
import { NewMenuApiService } from './new-menu-api.service';
import { arrayDiffIdCompare, fromArray } from '../shared/rxjs-utils';
import { FileCacheService } from './file-cache.service';

interface ToUpdate<T> {
  origin: T;
  value: T;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class NewMenuSyncService {
  public menuGCategory: IMenuGCategory[] = [];

  constructor(
    private readonly store: NewMenuStorageService,
    protected readonly network: ConnectionStatusService,
    private readonly menuApi: NewMenuApiService,
    private readonly filesCache: FileCacheService
  ) {
    this.menuApi.menuGCategory.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe((gCategory) => {
      this.menuGCategory = gCategory;
      this.initMenu();
    });
  }

  private updateMenuData(): Observable<void> {
    return forkJoin(
      this.menuGCategory.map(gCategory => {
        return this.menuApi.getCategory(gCategory.hierarchyId).pipe(
          map((categories: IMenuCategoryRaw[]) => ({ hierarchyId: gCategory.hierarchyId, categories })),
          catchError(error => {
            return of({ hierarchyId: gCategory.hierarchyId, categories: [] });
          })
        );
      })
    ).pipe(
      switchMap(cats => this.sync(cats)),
      map(() => void 0),
      catchError(error => {
        console.error('Error during sync', error);
        return of(void 0);
      })
    );
  }

  public initMenu(): void {
    console.log('Init Menu');
    this.store.isLoading.next(true);

    if (this.menuGCategory && this.menuGCategory.length > 0) {
      this.updatePeriodical(10 * 60 * 1000).pipe(
        untilDestroyed(this)
      ).subscribe(
        () => {
          this.store.isLoading.next(false);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('MenuGCategory is empty, skipping init');
      this.store.isLoading.next(false);
    }
  }

  public updatePeriodical(period: number): Observable<void> {
    const repeatDelay = () => merge(
      this.network.status$.pipe(
        distinctUntilChanged(),
        filter((s) => s)
      ),
      this.menuApi.menuGCategory.pipe(
        distinctUntilChanged(),
        filter((categories) => categories.length > 0)
      )
    ).pipe(
      tap(() => console.log(`Sync delay until ${new Date(Date.now() + period).toLocaleString()}`)),
      delay(period),
      tap(() => console.log('Sync restart'))
    );

    return this.updateMenuData().pipe(
      repeatWhen((completed) => completed.pipe(switchMap(repeatDelay))),
      retryWhen((errors) => errors.pipe(switchMap(repeatDelay)))
    );
  }

  private processOptions(options: IConfigurationOptions[]): number[] {
    return options.map((option: IConfigurationOptions) => {
      const currentOption: IConfigurationOptions = {
        name: option.name,
        productID: option.productID,
        productPrice: option.productPrice,
        sku: option.sku
      };

      return option.productID;
    });
  }

  private processConfigurations(configurations: IConfigurationsRaw[]): number[] {
    return configurations.map((config: IConfigurationsRaw) => {
      const currentConfig: IConfigurations = {
        name: config.name,
        minqty: config.minqty,
        maxqty: config.maxqty,
        id: config.id,
        options: config.options ? this.processOptions(config.options) : undefined
      };

      return config.id;
    });
  }

  private processUpsellItem(upsellItem: IUpsellItemRaw): number {
    const upsell: IUpsellItem = {
      customFields: upsellItem.customFields ? upsellItem.customFields : undefined,
      description: upsellItem.description,
      isVegetarian: upsellItem.isVegetarian,
      name: upsellItem.name,
      id: upsellItem.id,
      productPrice: upsellItem.productPrice,
      showImage: upsellItem.showImage,
      sku: upsellItem.sku,
      configurations: upsellItem.configurations ? this.processConfigurations(upsellItem.configurations) : undefined
    };

    return upsellItem.id;
  }

  private processProducts(products: IProductsRaw[], hierarchyId: number, subCategoryId: number): IProducts[] {
    return products.map((product: IProductsRaw) => {
      const volume = product.description.split('|').filter(v => /^\s*\d+oz\s*$/.test(v)).map(v => v.trim());
      const currentProduct: IProducts = {
        id: product.productID,
        name: product.name,
        showImage: product.showImage,
        productPrice: product.productPrice,
        isVegetarian: product.isVegetarian,
        description: product.description,
        calories: product.calories,
        allergenList: product.allergenList?.split(','),
        upgrades: product.upgrades ? product.upgrades.map((upgrade: IProductsRaw) => upgrade.productID) : [],
        upsellItem: product.upsellItem ? this.processUpsellItem(product.upsellItem) : undefined,
        customFields: product.customFields ? product.customFields : undefined,
        sku: 0,
        hierarchyId,
        parentSubCategoryId: subCategoryId
      };

      if (volume.length > 0) {
        currentProduct.volume = volume;
      }

      if (hierarchyId !== 723 && hierarchyId !== 735) {
        currentProduct.sku = this.menuApi.getImgUrl(product.sku);
      }

      return currentProduct;
    });
  }

  private processSubCategory(subCategories: ISubCategoriesRaw[], hierarchyId: number, categoryId: number):
    {
      subCategory: ISubCategories,
      products: IProducts[]
    }[] {
    return subCategories.map((subCategory: ISubCategoriesRaw) => {
      const currentSubCategory: ISubCategories = {
        id: subCategory.categoryID,
        categoryName: subCategory.categoryName,
        parentCategoryId: categoryId
      };

      const products = this.processProducts(subCategory.products, hierarchyId, subCategory.categoryID);

      return { subCategory: currentSubCategory, products };
    });
  }

  public processCategory(category: IMenuCategoryRaw, hierarchyId: number):
    {
      category: IMenuCategory,
      subCategories: {
        subCategory: ISubCategories,
        products: IProducts[]
      }[]
    } {
    const subCategories = this.processSubCategory(category.subCategories, hierarchyId, category.categoryID);
    const bgSku = category.subCategories[0].products[0].sku;

    const currentCategory: IMenuCategory = {
      id: category.categoryID,
      categoryName: category.categoryName,
      bgSku: hierarchyId !== 723 ? this.menuApi.getImgUrl(bgSku) : 0,
      hierarchyId
    };

    return { category: currentCategory, subCategories };
  }

  private toAddProducts(products: IProducts[]): Observable<IProducts[]> {
    console.log('ToAddProduct =======<', products);
    return from(products).pipe(
      mergeMap((product) => {
          if (product.name !== 'Desc') {
            return this.store.addProduct(product).pipe(
              filter(prod => !!prod),
              mergeMap((trueProduct) =>
                typeof trueProduct.sku !== 'string'
                  ? of(trueProduct)
                  : this.filesCache.getFile(trueProduct.sku).pipe(
                    map(() => trueProduct)
                  )
              )
            );
          } else {
            return EMPTY;
          }
      }),
      toArray(),
      tap(() => console.log('ToAddProduct =======<')),
      untilDestroyed(this)
    );
  }

  public toUpdateProducts(products: ToUpdate<IProducts>[]): Observable<IProducts[]> {
    console.log('ToUpdateProduct =======');
    return fromArray(products).pipe(
      mergeMap(({ origin, value }) => this.store.updateProduct(value).pipe(
        map((product) => ({
          origin,
          product
        }))
      )),
      mergeMap(({origin, product}) => {
        if (!product) {
          return EMPTY;
        }

        if (product.sku === origin.sku || (typeof product.sku !== 'string' && typeof origin.sku !== 'string')) {
          return of(product);
        }

        if (typeof product.sku !== 'string' && typeof origin.sku === 'string') {
          return this.filesCache.delete(origin.sku).pipe(
            map(() => product)
          );
        }

        if (typeof product.sku === 'string' && typeof origin.sku !== 'string') {
          return this.filesCache.getFile(product.sku).pipe(
            map(() => product)
          );
        }

        if (typeof product.sku === 'string' && typeof origin.sku === 'string') {
          return this.filesCache.delete(origin.sku).pipe(
            mergeMap(() => this.filesCache.getFile(product.sku as string)),
            map(() => product)
          );
        }

        return of(product);
      }),
      toArray()
    );
  }

  private toDeleteProducts(products: IProducts[]): Observable<IProducts[]> {
    console.log('ToDeleteProduct =======');
    return from(products).pipe(
      mergeMap((product) =>
        this.store.deleteProduct(product).pipe(
          filter((deletedProduct): deletedProduct is IProducts => !!deletedProduct),
          mergeMap((deletedProduct) =>
            typeof deletedProduct.sku !== 'string'
              ? of(deletedProduct)
              : this.filesCache.delete(deletedProduct.sku).pipe(
                map(() => deletedProduct)
              )
          )
        )
      ),
      toArray(),
      tap(() => console.log('ToDeleteProduct complete ======='))
    );
  }

  private toAddSubCategories(subCategories: ISubCategories[]): Observable<ISubCategories[]> {
    console.log('ToAddSubCategories  +++++++');
    return fromArray(subCategories).pipe(
      mergeMap((subCategory) => this.store.addSubCategories(subCategory)),
      toArray(),
      untilDestroyed(this)
    );
  }

  private toUpdateSubCategories(subCategories: ToUpdate<ISubCategories>[]): Observable<ISubCategories[]> {
    console.log('ToUpdateSubCategories  +++++++');
    return fromArray(subCategories).pipe(
      switchMap(({ origin, value }) => this.store.updateSubCategories(value)),
      mergeMap((category) => {
        if (!category) {
          return EMPTY;
        }
        return of(category);
      }),
      toArray(),
      untilDestroyed(this)
    );
  }

  private toDeleteSubCategories(subCategories: ISubCategories[]): Observable<ISubCategories[]> {
    console.log('ToDeleteSubCategories  +++++++');
    return from(subCategories).pipe(
      mergeMap((subCategory) => this.store.deleteSubCategories(subCategory)),
      filter((category): category is ISubCategories => !!category),
      toArray(),
      tap(() => console.log('ToDeleteSubCategories complete +++++++')),
      untilDestroyed(this)
    );
  }

  private toAddCategory(categories: IMenuCategory[]): Observable<IMenuCategory[]> {
    console.log('To add category ----<', categories);
    return from(categories).pipe(
      mergeMap((category) =>
        this.store.addCategory(category).pipe(
          filter(cat => !!cat),
          mergeMap((trueCategory) =>
            typeof trueCategory.bgSku !== 'string'
              ? of(trueCategory)
              : this.filesCache.getFile(trueCategory.bgSku).pipe(
                map(() => trueCategory)
              )
          )
        )
      ),
      toArray(),
      tap(() => console.log('To add category ---->')),
      untilDestroyed(this)
    );
  }

  private toUpdateCategory(categories: ToUpdate<IMenuCategory>[]): Observable<IMenuCategory[]>{
    console.log('To update category ----<');
    return fromArray(categories).pipe(
      mergeMap(({ origin, value }) => this.store.updateCategory(value).pipe(
        map((category) => ({
          origin,
          category
        }))
      )),
      mergeMap(({origin, category}) => {
        if (!category) {
          return EMPTY;
        }

        if (category.bgSku === origin.bgSku || (typeof category.bgSku !== 'string' && typeof origin.bgSku !== 'string')) {
          return of(category);
        }

        if (typeof category.bgSku !== 'string' && typeof origin.bgSku === 'string') {
          return this.filesCache.delete(origin.bgSku).pipe(
            map(() => category)
          );
        }

        if (typeof category.bgSku === 'string' && typeof origin.bgSku !== 'string') {
          return this.filesCache.getFile(category.bgSku).pipe(
            map(() => category)
          );
        }

        if (typeof category.bgSku === 'string' && typeof origin.bgSku === 'string') {
          return this.filesCache.delete(origin.bgSku).pipe(
            mergeMap(() => this.filesCache.getFile(category.bgSku as string)),
            map(() => category)
          );
        }

        return of(category);
      }),
      toArray(),
      untilDestroyed(this)
    );
  }

  private toDeleteCategory(categories: IMenuCategory[]): Observable<IMenuCategory[]> {
    console.log('To delete category -----<');
    return from(categories).pipe(
      mergeMap((category) =>
        this.store.deleteCategory(category).pipe(
          filter((cat): cat is IMenuCategory => !!cat),
          mergeMap((trueCategory) =>
            typeof trueCategory.bgSku !== 'string'
              ? of(trueCategory)
              : this.filesCache.delete(trueCategory.bgSku).pipe(
                map(() => trueCategory)
              )
          )
        )
      ),
      toArray(),
      tap(() => console.log('To delete category ---->')),
      untilDestroyed(this)
    );
  }

  private syncProducts(products: IProducts[]): Observable<IProducts[]> {
    console.log('syncProducts ======<');

    return this.store.getAllProducts().pipe(
      mergeMap((currentProducts) => {
        const add = arrayDiff(products, currentProducts, arrayDiffIdCompare);
        const del = arrayDiff(currentProducts, products, arrayDiffIdCompare);
        const upd = currentProducts.reduce<ToUpdate<IProducts>[]>((acc, origin) => {
          const value = products.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }
          return acc;
        }, [] );

        return forkJoin([
          this.toAddProducts(add).pipe(catchError(error => {
            console.error('Error adding products:', error);
            return of([]);
          })),
          this.toUpdateProducts(upd).pipe(catchError(error => {
            console.error('Error updating products:', error);
            return of([]);
          })),
          this.toDeleteProducts(del).pipe(catchError(error => {
            console.error('Error deleting products:', error);
            return of([]);
          }))
        ]).pipe(
          map(() => products)
        );
      }),
      tap((p) => console.log('Product after sync =====>')),
      untilDestroyed(this)
    );
  }


  private syncSubCategories(processSubCategories: IProcessSubCategories[]): Observable<IProducts[]> {
    console.log('syncSubCategories ++++++++<');

    const newSubcategories = processSubCategories.reduce<ISubCategories[]>((acc, item) => {
      return acc.concat(item.subCategory);
    }, []);

    return this.store.getAllSub().pipe(
      mergeMap((currentSubcategories) => {
        const add = arrayDiff(newSubcategories, currentSubcategories, arrayDiffIdCompare);
        const del = arrayDiff(currentSubcategories, newSubcategories, arrayDiffIdCompare);
        const upd = currentSubcategories.reduce<ToUpdate<ISubCategories>[]>((acc, origin) => {
          const value = newSubcategories.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }
          return acc;
        }, []);

        return forkJoin([
          this.toAddSubCategories(add).pipe(catchError(error => {
            console.error('Error adding subcategories:', error);
            return of([]);
          })),
          this.toUpdateSubCategories(upd).pipe(catchError(error => {
            console.error('Error updating subcategories:', error);
            return of([]);
          })),
          this.toDeleteSubCategories(del).pipe(catchError(error => {
            console.error('Error deleting subcategories:', error);
            return of([]);
          }))
        ]).pipe(
          map(() => {
            const productMap = processSubCategories.reduce<{ [id: number]: IProducts }>((acc, subCategory) => {
              subCategory.products.forEach((product) => {
                const existingProduct = acc[product.id];
                if (existingProduct) {
                  if (Array.isArray(existingProduct.parentSubCategoryId))  {
                    existingProduct.parentSubCategoryId.push(product.parentSubCategoryId as number);
                  } else {
                    existingProduct.parentSubCategoryId = [existingProduct.parentSubCategoryId, product.parentSubCategoryId as number];
                  }
                } else {
                  acc[product.id] = {
                    ...product
                  };
                }
              });
              return acc;
            }, {});

            return Object.values(productMap);
          })
        );
      }),
      tap((p) => console.log('Product after syncSubSync ++++++>')),
      untilDestroyed(this)
    );
  }

  private syncCategories(processCategories: IProcessCategory[]): Observable<IProcessSubCategories[]> {
    console.log('sync Categories ------<');
    return this.store.getAllCategory().pipe(
      mergeMap((currentCategories) => {
        const newCategories = processCategories.map(processCategory => processCategory.category);
        const add = arrayDiff(newCategories, currentCategories, arrayDiffIdCompare);
        const del = arrayDiff(currentCategories, newCategories, arrayDiffIdCompare);
        const upd = currentCategories.reduce<ToUpdate<IMenuCategory>[]>((acc, origin) => {
          const value = newCategories.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }
          return acc;
        }, []);

        return forkJoin([
          this.toAddCategory(add).pipe(catchError(error => {
            console.error('Error adding categories:', error);
            return of([]);
          })),
          this.toUpdateCategory(upd).pipe(catchError(error => {
            console.error('Error updating categories:', error);
            return of([]);
          })),
          this.toDeleteCategory(del).pipe(catchError(error => {
            console.error('Error deleting categories:', error);
            return of([]);
          }))
          ]).pipe(
          map(() => processCategories.reduce<IProcessSubCategories[]>((acc, processCategory) => {
            return acc.concat(processCategory.subCategories);
          }, []))
        );
      }),
      tap(r => console.log('After sync Categories ------->')),
      untilDestroyed(this)
    );
  }

  // tslint:disable-next-line:typedef
  public sync(allCategories: { hierarchyId: number, categories: IMenuCategoryRaw[] }[]) {
    return from(allCategories).pipe(
      mergeMap(({ hierarchyId, categories }) => from(categories).pipe(
        map(category => this.processCategory(category, hierarchyId)),
      )),
      toArray(),
      switchMap((processCategories: IProcessCategory[]) => this.syncCategories(processCategories)),
      switchMap((processSubCategories: IProcessSubCategories[]) => this.syncSubCategories(processSubCategories)),
      switchMap((products) => this.syncProducts(products)),
      tap(() => console.log('Sync completed')),
      untilDestroyed(this)
    );
  }
}
