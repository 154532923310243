import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISectionData } from '../kelseys-sections-grid/kelseys-sections-grid.component';

@Component({
  selector: 'app-kelseys-more-section',
  templateUrl: './kelseys-more-section.component.html',
  styleUrls: ['./kelseys-more-section.component.scss']
})
export class KelseysMoreSectionComponent {

  @Output() backToSections = new EventEmitter<boolean>();
  @Input() data!: ISectionData;

  public back(): void {
    this.backToSections.emit(true);
  }

}
